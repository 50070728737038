import { useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  usePostOnboardingAuthcodeConfirmEmail,
  usePostOnboardingAuthcodeEmail,
  usePostOnboardingValidateEmail,
} from '../services-hooks/hooks';
import {
  CodeConfirmResult,
  LoginResponseDto,
  OnboardingDto,
} from '../services-hooks/types';
import { Header } from './header.component';
import { Button } from './ui/button.component';
import { Input } from './ui/input.component';

interface EmailValidatorProps {
  onboarding: OnboardingDto;
  onSessionStart: (loginResponse: LoginResponseDto) => void;
  onGoToSignup: VoidFunction;
  onGoBack: VoidFunction;
}

interface OnboardingEmailValidationFormValue {
  email: string;
}

export const EmailValidator: React.FC<EmailValidatorProps> = ({
  onboarding,
  onSessionStart,
  onGoToSignup,
  onGoBack,
}) => {
  const [emailForConfirmation, setEmailForConfirmation] = useState<
    string | null
  >(null);

  return (
    <>
      <Header onGoBack={onGoBack} />
      <div className="p-5">
        {!emailForConfirmation ? (
          <OnboardingEmailValidation
            onboarding={onboarding}
            onConfirmationRequired={(email) => setEmailForConfirmation(email)}
            onSuccess={onGoToSignup}
          />
        ) : (
          <OnboardingEmailConfirmation
            email={emailForConfirmation}
            onboarding={onboarding}
            onSuccess={onGoToSignup}
            onSessionStart={(login) => onSessionStart(login)}
          />
        )}
      </div>
    </>
  );
};

interface OnboardingEmailValidationProps {
  onboarding: OnboardingDto;
  onConfirmationRequired: (email: string) => void;
  onSuccess: () => void;
}

export const OnboardingEmailValidation: React.FC<
  OnboardingEmailValidationProps
> = ({ onboarding, onConfirmationRequired, onSuccess }) => {
  const { register, formState, watch, handleSubmit, setError } =
    useForm<OnboardingEmailValidationFormValue>();
  const { mutateAsync, isLoading: validateLoading } =
    usePostOnboardingValidateEmail();
  const { mutateAsync: sendAuthCode, isLoading: authcodeSendLoading } =
    usePostOnboardingAuthcodeEmail();
  const emailValue = watch('email');
  const siteKey = '6LcLbGwmAAAAAEC0AthraluDkoT9YwLDiw3bywTt';
  const onSubmit = async (value: OnboardingEmailValidationFormValue) => {
    try {
      const response = await mutateAsync({
        requestBody: {
          onboardingId: onboarding.id,
          email: value.email,
        },
      });
      if (response.emailValidationRequired) {
        const token: string = await (
          window as any
        ).grecaptcha.enterprise.execute(siteKey, {
          action: 'email_send',
        });
        await sendAuthCode({
          requestBody: {
            onboardingId: onboarding.id,
            recaptcha: token,
            siteKey,
          },
        });
        onConfirmationRequired(value.email);
      } else {
        onSuccess();
      }
    } catch (e) {
      setError('email', {
        message: 'Nie udało się zweryfikować adresu email, spróbuj ponownie.',
      });
    }
  };
  const isLoading = authcodeSendLoading || validateLoading;
  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className="mx-auto max-w-sm">
        <h1 className="text-center text-2xl text-white font-bold mb-2">
          Podaj adres e-mail
        </h1>
        <p className="text-white text-lg text-center">
          Podaj swój adres e-mail aby przejść dalej:
        </p>
        <Input
          center
          placeholder="Email"
          autoFocus
          className="mt-5"
          {...register('email', { required: true })}
          error={formState.errors.email?.message}
        />
        <Button
          loading={isLoading}
          disabled={!emailValue || emailValue?.length === 0 || isLoading}
          className="mt-5"
        >
          Dalej
        </Button>
      </form>
      <div className="text-center text-white mt-20">
        <p className="font-bold mb-1">Dlaczego prosimy o te dane?</p>
        <p className="text-sm">
          Chcemy zweryfikować czy należysz już do klubu Kocham Wino jako
          posiadacz manualnej karty papierowej.
        </p>
      </div>
      <div className="text-center text-white mt-8">
        <p className="font-bold mb-1">Potrzebujesz pomocy lub masz pytania?</p>
        <p className="text-sm">
          Napisz do nas:{' '}
          <span className="underline">kochamwino@kochamwino.com.pl</span>
        </p>
      </div>
    </>
  );
};

interface OnboardingEmailConfirmationProps {
  onboarding: OnboardingDto;
  email: string;
  onSuccess: VoidFunction;
  onSessionStart: (login: LoginResponseDto) => void;
}
interface OnboardingEmailConfirmationFormValue {
  code: string;
}
export const OnboardingEmailConfirmation: React.FC<
  OnboardingEmailConfirmationProps
> = ({ onboarding, email, onSuccess, onSessionStart }) => {
  const { register, formState, watch, handleSubmit, setError } =
    useForm<OnboardingEmailConfirmationFormValue>();
  const { mutateAsync, isLoading } = usePostOnboardingAuthcodeConfirmEmail();
  const codeValue = watch('code');
  const { mutateAsync: sendAuthCode, isLoading: authcodeSendLoading } =
    usePostOnboardingAuthcodeEmail();
  const onSubmit = async (value: OnboardingEmailConfirmationFormValue) => {
    try {
      const response = await mutateAsync({
        requestBody: {
          onboardingId: onboarding.id,
          code: value.code,
        },
      });
      if (response.type === CodeConfirmResult.Onboarding) {
        onSuccess();
      } else {
        onSessionStart(response.session!);
      }
    } catch (e) {
      setError('code', {
        message: 'Nie udało się zweryfikować adresu email, spróbuj ponownie.',
      });
    }
  };
  const onResend = async () => {
    try {
      const siteKey = '6LcLbGwmAAAAAEC0AthraluDkoT9YwLDiw3bywTt';
      const token: string = await (window as any).grecaptcha.enterprise.execute(
        siteKey,
        {
          action: 'email_send',
        },
      );
      const response = await sendAuthCode({
        requestBody: {
          onboardingId: onboarding.id,
          siteKey: siteKey,
          recaptcha: token,
        },
      });
    } catch (e) {
      console.error(e);
    }
  };
  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className="mx-auto max-w-sm">
        <h1 className="text-center text-2xl text-white font-bold mb-2">
          Wpisz 6-cyfrowy kod wysłany na {email}
        </h1>
        <p className="text-white text-lg text-center">Wpisz kod E-mail:</p>
        <Input
          placeholder="000000"
          autoFocus
          className="mt-5"
          {...register('code', { required: true })}
          error={formState.errors.code?.message}
        />
        <Button
          loading={isLoading}
          disabled={!codeValue || codeValue?.length === 0 || isLoading}
          className="mt-5"
        >
          Dalej
        </Button>
      </form>
      <div className="text-center text-white mt-5 px-5 lg:px-0 flex flex-col sm:flex-row items-center justify-center">
        <p className="font-bold mr-2">Nie otrzymałeś kodu e-mail? </p>
        <button
          onClick={onResend}
          className="underline"
          disabled={authcodeSendLoading}
        >
          Wyślij ponownie!
        </button>
      </div>
      <div className="text-center text-white mt-20">
        <p className="font-bold mb-1">Dlaczego prosimy o te dane?</p>
        <p className="text-sm">
          Chcemy zweryfikować czy należysz już do klubu Kocham Wino jako
          posiadacz manualnej karty papierowej.
        </p>
      </div>
      <div className="text-center text-white mt-8">
        <p className="font-bold mb-1">Potrzebujesz pomocy lub masz pytania?</p>
        <p className="text-sm">
          Napisz do nas:{' '}
          <span className="underline">kochamwino@kochamwino.com.pl</span>
        </p>
      </div>
    </>
  );
};
