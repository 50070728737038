
export const countries = [
  {
    name: "Polska",
    dial_code: '+48',
    emoji: '🇵🇱',
    code: "PL",
  },
  {
    name: 'Albania',
    dial_code: '+355',
    emoji: '🇦🇱',
    code: 'AL',
  },
  {
    name: 'Andora',
    dial_code: '+376',
    emoji: '🇦🇩',
    code: 'AD',
  },
  {
    name: 'Armenia',
    dial_code: '+374',
    emoji: '🇦🇲',
    code: 'AM',
  },
  {
    name: 'Austria',
    dial_code: '+43',
    emoji: '🇦🇹',
    code: 'AT',
  },
  {
    name: 'Belgia',
    dial_code: '+32',
    emoji: '🇧🇪',
    code: 'BE',
  },
  {
    name: 'Bośnia i Hercegowina',
    dial_code: '+387',
    emoji: '🇧🇦',
    code: 'BA',
  },
  {
    name: "Bułgaria",
    dial_code: '+359',
    emoji: '🇧🇬',
    code: "BG",
  },
  {
    name: "Chorwacja",
    dial_code: '+385',
    emoji: '🇭🇷',
    code: "HR",
  },
  {
    name: "Cypr",
    dial_code: '+357',
    emoji: '🇨🇾',
    code: "CY",
  },
  {
    name: "Republika Czeska",
    dial_code: '+420',
    emoji: '🇨🇿',
    code: "CZ",
  },
  {
    name: "Dania",
    dial_code: '+45',
    emoji: '🇩🇰',
    code: "DK",
  },
  {
    name: "Estonia",
    dial_code: '+372',
    emoji: '🇪🇪',
    code: "EE",
  },
  {
    name: "Wyspy Owcze",
    dial_code: '+298',
    emoji: '🇫🇴',
    code: "FO",
  },
  {
    name: "Finlandia",
    dial_code: '+358',
    emoji: '🇫🇮',
    code: "FI",
  },
  {
    name: "Francja",
    dial_code: '+33',
    emoji: '🇫🇷',
    code: "FR",
  },
  {
    name: "Gruzja",
    dial_code: '+995',
    emoji: '🇬🇪',
    code: "GE",
  },
  {
    name: "Niemcy",
    dial_code: '+49',
    emoji: '🇩🇪',
    code: "DE",
  },
  {
    name: "Gibraltar",
    dial_code: '+350',
    emoji: '🇬🇮',
    code: "GI",
  },
  {
    name: "Grecja",
    dial_code: '+30',
    emoji: '🇬🇷',
    code: "GR",
  },
  {
    name: "Stolica Apostolska (Państwo Watykańskie)",
    dial_code: '+39',
    emoji: '🇻🇦',
    code: "VA",
  },
  {
    name: "Węgry",
    dial_code: '+36',
    emoji: '🇭🇺',
    code: "HU",
  },
  {
    name: "Islandia",
    dial_code: '+354',
    emoji: '🇮🇸',
    code: "IS",
  },
  {
    name: "Irlandia",
    dial_code: '+353',
    emoji: '🇮🇪',
    code: "IE",
  },
  {
    name: "Wyspa Man",
    dial_code: '+44',
    emoji: '🇮🇲',
    code: "IM",
  },
  {
    name: "Włochy",
    dial_code: '+39',
    emoji: '🇮🇹',
    code: "IT",
  },
  {
    name: "Łotwa",
    dial_code: '+371',
    emoji: '🇱🇻',
    code: "LV",
  },
  {
    name: "Liechtenstein",
    dial_code: '+423',
    emoji: '🇱🇮',
    code: "LI",
  },
  {
    name: "Litwa",
    dial_code: '+370',
    emoji: '🇱🇹',
    code: "LT",
  },
  {
    name: "Luksemburg",
    dial_code: '+352',
    emoji: '🇱🇺',
    code: "LU",
  },
  {
    name: "Macedonia",
    dial_code: '+389',
    emoji: '🇲🇰',
    code: "MK",
  },
  {
    name: "Malta",
    dial_code: '+356',
    emoji: '🇲🇹',
    code: "MT",
  },
  {
    name: "Kosowo",
    dial_code: '+383',
    emoji: '🇽🇰',
    code: "XK",
  },
  {
    name: "Mołdawia",
    dial_code: '+373',
    emoji: '🇲🇩',
    code: "MD",
  },
  {
    name: "Monako",
    dial_code: '+377',
    emoji: '🇲🇨',
    code: "MC",
  },
  {
    name: "Czarnogóra",
    dial_code: '+382',
    emoji: '🇲🇪',
    code: "ME",
  },
  {
    name: "Holandia",
    dial_code: '+31',
    emoji: '🇳🇱',
    code: "NL",
  },
  {
    name: "Norwegia",
    dial_code: '+47',
    emoji: '🇳🇴',
    code: "NO",
  },
  {
    name: "Portugalia",
    dial_code: '+351',
    emoji: '🇵🇹',
    code: "PT",
  },
  {
    name: "Rumunia",
    dial_code: '+40',
    emoji: '🇷🇴',
    code: "RO",
  },
  {
    name: "San Marino",
    dial_code: '+378',
    emoji: '🇸🇲',
    code: "SM",
  },
  {
    name: "Serbia",
    dial_code: '+381',
    emoji: '🇷🇸',
    code: "RS",
  },
  {
    name: "Słowacja",
    dial_code: '+421',
    emoji: '🇸🇰',
    code: "SK",
  },
  {
    name: "Słowenia",
    dial_code: '+386',
    emoji: '🇸🇮',
    code: "SI",
  },
  {
    name: "Hiszpania",
    dial_code: '+34',
    emoji: '🇪🇸',
    code: "ES",
  },
  {
    name: "Szwecja",
    dial_code: '+46',
    emoji: '🇸🇪',
    code: "SE",
  },
  {
    name: "Szwajcaria",
    dial_code: '+41',
    emoji: '🇨🇭',
    code: "CH",
  },
  {
    name: "Turcja",
    dial_code: '+90',
    emoji: '🇹🇷',
    code: "TR",
  },
  {
    name: "Ukraina",
    dial_code: '+380',
    emoji: '🇺🇦',
    code: 'UA',
  },
  {
    name: "Wielka Brytania",
    dial_code: '+44',
    emoji: '🇬🇧',
    code: "GB",
  },
];
