//@ts-nocheck
/**
 * AUTO_GENERATED Do not change this file directly, use config.ts file instead
 *
 * @version 6
 */

export interface AlcoholCountryDto {
  country: string;
  regions: string[];
  iso_code?: string;
}

export interface AlcoholDto {
  alcohol_barrel: string;
  alcohol_barrel_array: string[];
  aplikacja: boolean;
  cena: number;
  cena_rabat: boolean;
  cena_specjalna: boolean;
  /**
   *
   * - Format: date-time
   */
  data_dodania: string;
  discontinued: boolean;
  fruit_desc_array: string[];
  grass_desc_array: string[];
  gwiazdki: number;
  id: number;
  liczba_glosow: number;
  limited: boolean;
  nagrody: string;
  nazwa: string;
  obrazek: string;
  ocena_sommeliera: string;
  opis: string;
  oxidation_desc_array: string[];
  packaging: boolean;
  peaty: boolean;
  peaty_desc_array: string[];
  pim_id: string;
  pojemnosc: number;
  published: boolean;
  rabat_specjalny: number;
  rabaty_nie: boolean;
  spicy: boolean;
  srednia_ocen: number;
  suma_ocen: number;
  tags: string;
  wood_desc_array: string[];
  age_description?: string;
  age_from?: number;
  age_to?: number;
  alcohol_subtype?: string;
  alcohol_type?: AlcoholType;
  alkohol?: number;
  cena_oryginalna?: number;
  fruit_desc?: string;
  grass_desc?: string;
  image_url?: string;
  kraj?: string;
  kraj_iso?: string;
  oxidation_desc?: string;
  peaty_desc?: string;
  producer?: string;
  thumbnail_url?: string;
  wood_desc?: string;
}

export interface AlcoholFiltersInfoDto {
  alcohol: FiltersRangeInfoDto;
  barrel: string[];
  countries: AlcoholCountryDto[];
  fruitAromas: string[];
  grainAromas: string[];
  grassAromas: string[];
  oxidationAromas: string[];
  peatyAromas: string[];
  price: FiltersRangeInfoDto;
  producers: string[];
  tags: string[];
  types: AlcoholTypeDto[];
  woodAromas: string[];
}

export interface AlcoholSearchResultDto {
  hits: AlcoholDto[];
  processingTimeMs: number;
  query: string;
}

export enum AlcoholType {
  BRANDY = "BRANDY",
  COGNAC = "COGNAC",
  GIN = "GIN",
  OKOVITA = "OKOVITA",
  LIKIER = "LIKIER",
  RUM = "RUM",
  TEQUILA = "TEQUILA",
  WHISKY = "WHISKY",
  WODKA = "WODKA",
  MIOD = "MIOD",
  NALEWKA_Z_OWOCOW = "NALEWKA Z OWOCOW",
  READY_TO_DRINK = "READY TO DRINK",
  BRAK_KATEGORII = "BRAK KATEGORII",
}

export interface AlcoholTypeDto {
  subtypes: string[];
  type: string;
}

export interface ApkBugDto {
  _id: string;
  autor: string;
  /**
   *
   * - Format: date-time
   */
  data: string;
  kategoria: string;
  rozwiazany: boolean;
  tresc: string;
  user_login: string;
}

export enum ApkStatus {
  OK = "OK",
  ACCEPTABLE = "ACCEPTABLE",
  OLD = "OLD",
}

export interface ArticleCategoryDto {
  id: number;
  kategoria: string;
  kolejnosc: number;
  obraz: string;
  opis: string;
}

export interface ArticleDto {
  autor: string;
  cena: number;
  /**
   *
   * - Format: date-time
   */
  data: string;
  id: number;
  kategoria: string;
  kolejnosc: number;
  /**
   *
   * - Format: date-time
   */
  modif_date: string;
  obraz: string;
  obraz_butelka: string;
  obraz_galeria: string[];
  published: boolean;
  punkty: number;
  tresc: string;
  tytul: string;
  kategoria_id?: number;
  ocena?: number;
}

export interface AuthCodeSendResponseDto {
  success: boolean;
  message_code: string;
  message: string;
}

export enum AuthMethod {
  both = "both",
  sms = "sms",
  email = "email",
  none = "none",
}

export interface CardDto {
  nazwa: string;
  obraz_karty: string;
  opiskorzysci: string;
  typ: string;
}

export interface CheckVersionBodyDto {
  platform: string;
  version: string;
}

export enum CodeConfirmResult {
  Session = "Session",
  Onboarding = "Onboarding",
}

export interface CommentDto {
  akceptowane: boolean;
  /**
   *
   * - Format: date-time
   */
  data: string;
  id: string;
  imie: string;
  komentarz: string;
  ocena: number;
}

export interface CommentWineBodyDto {
  id_wina: string;
  notatka: string;
}

export interface ConfirmSignupDto {
  code: string;
  user_id: string;
  password?: string;
  store?: boolean;
}

export interface ConsentDto {
  consent_email: boolean;
  consent_privacy: boolean;
  consent_program: boolean;
  consent_sms: boolean;
  consent_winezja_email: boolean;
  consent_winezja_sms: boolean;
  zgoda_call: boolean;
  zgoda_email: boolean;
  zgoda_program: boolean;
  zgoda_sms: boolean;
}

export interface ConsentHistoryDto {
  /**
   *
   * - Format: date-time
   */
  data: string;
  email: boolean;
  polityka: boolean;
  program: boolean;
  sms: boolean;
  winezja_email: boolean;
  winezja_sms: boolean;
  user?: string;
}

export interface CreateNotificationBodyDto {
  data: string;
  kategoria: string;
  tresc: string;
}

export interface DeleteNotificationBodyDto {
  id: string;
}

export interface DeviceSetupBodyDto {
  device_token: string;
  platform: string;
  version: string;
}

export interface EmailAuthCodeConfirmBodyDto {
  code: string;
}

export interface EmailChangeBodyDto {
  newEmail: string;
}

export interface EmailChangeConfirmBodyDto {
  code: string;
}

export interface EmailConfirmResponseDto {
  type: CodeConfirmResult;
  onboarding?: OnboardingDto;
  session?: LoginResponseDto;
}

export interface FiltersRangeInfoDto {
  max: number;
  min: number;
}

export interface GatherImageDataBodyDto {
  data: string;
  pim_id: number;
}

export interface GetProductFiltersAlcoholQueryParams {
  search: string;
  type?: string;
}

export interface GetTransactionHistoryCardQueryParams {
  cursor?: string;
}

export interface GetTransactionHistoryDetailsCardQueryParams {
  date: string;
  id?: string;
}

export interface GetTransactionHistoryDetailsQueryParams {
  date: string;
  id?: string;
}

export interface GetTransactionHistoryDetailsV2CardQueryParams {
  date: string;
  id?: string;
}

export interface GetTransactionHistoryDetailsV2QueryParams {
  date: string;
  id?: string;
}

export interface GetTransactionHistoryQueryParams {
  cursor?: string;
}

export interface GetTransactionHistoryV2CardQueryParams {
  cursor?: string;
}

export interface GetTransactionHistoryV2QueryParams {
  cursor?: string;
}

export interface KeycloakLoginResponseDto {
  access_token: string;
  expires_in: number;
  refresh_expires_in: number;
  refresh_token: string;
  token_type: string;
}

export interface LoginBodyDto {
  client_id: string;
  device_token: string;
  password: string;
  username: string;
  platform?: string;
}

export interface LoginClientCredentialsBodyDto {
  client_id: string;
  client_secret: string;
}

export interface LoginResponseDto {
  access_token: string;
  expires_in: number;
  refresh_expires_in: number;
  refresh_token: string;
  syneriseToken: string;
  token_type: string;
}

export interface MarketingCommunicationsBodyDto {
  call: boolean;
  email: boolean;
  program: boolean;
  sms: boolean;
  user_id: string;
}

export interface MessageDto {
  message: string;
}

export interface NotificationDto {
  active: boolean;
  /**
   *
   * - Format: date-time
   */
  data: string;
  id: number;
  kategoria: string;
  tresc: string;
  user_login: string;
}

export interface OnboardAuthDto {
  auth_method: AuthMethod;
  user_id: string;
}

export interface OnboardingDto {
  emailChecked: boolean;
  emailValidated: boolean;
  emailValidationRequired: boolean;
  id: string;
  phone: string;
  email?: string;
}

export interface OnboardingEmailAuthCodeConfirmBodyDto {
  code: string;
  onboardingId: string;
}

export interface OnboardingEmailAuthCodeSendBodyDto {
  onboardingId: string;
  recaptcha: string;
  siteKey: string;
}

export interface OnboardingEmailValidateBodyDto {
  email: string;
  onboardingId: string;
}

export interface OnboardingSignupBodyDto {
  birth: string;
  consent_email: boolean;
  consent_privacy: boolean;
  consent_program: boolean;
  consent_sms: boolean;
  consent_winezja_email: boolean;
  consent_winezja_sms: boolean;
  firstname: string;
  lastName: string;
  onboardingId: string;
  referral: SendReferralCodeDto;
  zip: string;
  source?: string;
}

export interface PasswordChangeBodyDto {
  newpassword: string;
}

export enum PasswordRemaindMethod {
  sms = "sms",
  email = "email",
}

export interface PasswordRemindBodyDto {
  auth_method?: PasswordRemaindMethod;
  email?: string;
  phone?: string;
}

export interface PasswordRemindResponseDto {
  message: string;
  userid: string;
}

export interface PhoneChangeBodyDto {
  newPhone: string;
  hashCode?: string;
}

export interface PhoneChangeConfirmBodyDto {
  code: string;
}

export interface PhoneConfirmResponseDto {
  type: CodeConfirmResult;
  onboarding?: OnboardingDto;
  session?: LoginResponseDto;
}

export interface PlaceDto {
  _id: string;
  adres: string;
  email: string;
  id: number;
  kategoria: string;
  lat: string;
  lon: string;
  nazwa: string;
  published: boolean;
  telefon: string;
  email_odbierz?: string;
  opis?: string;
  telefon_odbierz?: string;
}

export interface PrivacyPolicyDto {
  /**
   *
   * - Format: date-time
   */
  data: string;
  id: number;
  nazwa: string;
  tresc: string;
}

export interface ProductDto {
  aplikacja: boolean;
  aromat: string;
  beczka: number;
  budowa: number;
  cena: number;
  cena_rabat: boolean;
  cena_specjalna: boolean;
  /**
   *
   * - Format: date-time
   */
  data_dodania: string;
  gwiazdki: number;
  id: number;
  kolor: string;
  komentarze: CommentDto[];
  kwasowosc: number;
  liczba_glosow: number;
  musujace: boolean;
  nagrody: string;
  nazwa: string;
  obrazek: string;
  ocena_sommeliera: string;
  opis: string;
  owocowe: boolean;
  pim_id: string;
  pojemnosc: number;
  published: boolean;
  rabat_specjalny: number;
  rabaty_nie: boolean;
  smak: string;
  spokojne: boolean;
  srednia_ocen: number;
  suma_ocen: number;
  szczep: string;
  tags: string;
  tanicznosc: number;
  type: "WINE" | "STRONG_ALCOHOL";
  usta: number;
  usta_opis: string;
  wzmocnione: boolean;
  alkohol?: number;
  cena_oryginalna?: number;
  image_url?: string;
  kraj?: string;
  kraj_iso?: string;
  thumbnail_url?: string;
}

export interface ProductFiltersInfoDto {
  aromas: string[];
  countries: string[];
  maxPrice: number;
  minPrice: number;
  strains: string[];
  tags: string[];
  tastes: string[];
}

export interface ProductSearchBodyDto {
  filter?: string;
  page?: number;
  pageSize?: number;
  query?: string;
  sortBy?: string[];
}

export interface ProductSearchResultDto {
  hits: ProductDto[];
  processingTimeMs: number;
  query: string;
}

export interface ProductTastePropertiesDto {
  aromat: string;
  beczka: number;
  budowa: number;
  kwasowosc: number;
  musujace: boolean;
  owocowe: boolean;
  smak: string;
  spokojne: boolean;
  tanicznosc: number;
  usta: number;
  usta_opis: string;
  wzmocnione: boolean;
}

export interface ProductV2Dto {
  aplikacja: boolean;
  aromat: string;
  beczka: number;
  budowa: number;
  cena: number;
  cena_rabat: boolean;
  cena_specjalna: boolean;
  /**
   *
   * - Format: date-time
   */
  data_dodania: string;
  gwiazdki: number;
  id: number;
  kolor: string;
  kwasowosc: number;
  liczba_glosow: number;
  musujace: boolean;
  nagrody: string;
  nazwa: string;
  obrazek: string;
  ocena_sommeliera: string;
  opis: string;
  owocowe: boolean;
  pim_id: string;
  pojemnosc: number;
  published: boolean;
  rabat_specjalny: number;
  rabaty_nie: boolean;
  smak: string;
  spokojne: boolean;
  srednia_ocen: number;
  suma_ocen: number;
  szczep: string;
  tags: string;
  tanicznosc: number;
  type: "WINE" | "STRONG_ALCOHOL";
  usta: number;
  usta_opis: string;
  wzmocnione: boolean;
  alkohol?: number;
  cena_oryginalna?: number;
  image_url?: string;
  kraj?: string;
  kraj_iso?: string;
  thumbnail_url?: string;
}

export interface ProductWithTransactionDto {
  aplikacja: boolean;
  aromat: string;
  beczka: number;
  budowa: number;
  cena: number;
  cena_rabat: boolean;
  cena_specjalna: boolean;
  /**
   *
   * - Format: date-time
   */
  data_dodania: string;
  gwiazdki: number;
  id: number;
  kolor: string;
  komentarze: CommentDto[];
  kupione: boolean;
  kwasowosc: number;
  liczba_glosow: number;
  musujace: boolean;
  nagrody: string;
  nazwa: string;
  notatka: string;
  notatka_data: string;
  obrazek: string;
  ocena: number;
  ocena_notatka: string;
  ocena_sommeliera: string;
  ocenione: boolean;
  opis: string;
  owocowe: boolean;
  pim_id: string;
  pojemnosc: number;
  polubione: boolean;
  published: boolean;
  rabat_specjalny: number;
  rabaty_nie: boolean;
  smak: string;
  spokojne: boolean;
  srednia_ocen: number;
  suma_ocen: number;
  szczep: string;
  tags: string;
  tanicznosc: number;
  type: "WINE" | "STRONG_ALCOHOL";
  usta: number;
  usta_opis: string;
  wzmocnione: boolean;
  zanotowane: boolean;
  alkohol?: number;
  cena_oryginalna?: number;
  image_url?: string;
  kraj?: string;
  kraj_iso?: string;
  /**
   *
   * - Format: date-time
   */
  kupione_data?: string;
  /**
   *
   * - Format: date-time
   */
  ocena_data?: string;
  /**
   *
   * - Format: date-time
   */
  polubione_data?: string;
  thumbnail_url?: string;
}

export interface ProductWithTransactionV2Dto {
  aplikacja: boolean;
  aromat: string;
  beczka: number;
  budowa: number;
  cena: number;
  cena_rabat: boolean;
  cena_specjalna: boolean;
  /**
   *
   * - Format: date-time
   */
  data_dodania: string;
  gwiazdki: number;
  id: number;
  kolor: string;
  kupione: boolean;
  kwasowosc: number;
  liczba_glosow: number;
  musujace: boolean;
  nagrody: string;
  nazwa: string;
  notatka: string;
  notatka_data: string;
  obrazek: string;
  ocena: number;
  ocena_notatka: string;
  ocena_sommeliera: string;
  ocenione: boolean;
  opis: string;
  owocowe: boolean;
  pim_id: string;
  pojemnosc: number;
  polubione: boolean;
  published: boolean;
  rabat_specjalny: number;
  rabaty_nie: boolean;
  smak: string;
  spokojne: boolean;
  srednia_ocen: number;
  suma_ocen: number;
  szczep: string;
  tags: string;
  tanicznosc: number;
  type: "WINE" | "STRONG_ALCOHOL";
  usta: number;
  usta_opis: string;
  wzmocnione: boolean;
  zanotowane: boolean;
  alkohol?: number;
  cena_oryginalna?: number;
  image_url?: string;
  kraj?: string;
  kraj_iso?: string;
  /**
   *
   * - Format: date-time
   */
  kupione_data?: string;
  /**
   *
   * - Format: date-time
   */
  ocena_data?: string;
  /**
   *
   * - Format: date-time
   */
  polubione_data?: string;
  thumbnail_url?: string;
}

export interface PromoReferenceDto {
  promo_id: number;
}

export interface PromotionDto {
  active: boolean;
  author: string;
  body: string;
  data: string;
  expiry_date: string;
  id: number;
  points: string;
  promo_indywidualna: string;
  promo_main: string;
  promo_ogolna: string;
  promo_slajder: string;
  promo_type: string;
  promotion_bottle: string;
  promotion_image: string;
  publish_date: string;
  push: string;
  push_body: string;
  push_days: string;
  push_title: string;
  title: string;
}

export interface PromotionSummaryDto {
  promo_main: PromotionDto;
}

export interface RateWineBodyDto {
  id_wina: string;
  notatka: string;
  stars: number;
}

export interface ReferralCreateResultDto {
  kod: string;
  message: string;
  points: number;
  purchase: number;
}

export interface ReferralDto {
  card: string;
  code: string;
  date: string;
}

export enum ReferralSource {
  other = "other",
  store = "store",
  winezja = "winezja",
  user = "user",
}

export interface RefreshTokenBodyDto {
  client_id: string;
  refresh_token: string;
}

export interface RefreshTokenResponseDto {
  access_token: string;
  expires_in: number;
  refresh_expires_in: number;
  refresh_token: string;
  token_type: string;
}

export interface RegulationDto {
  aktywny: boolean;
  /**
   *
   * - Format: date-time
   */
  data: string;
  id: number;
  nazwa: string;
  tresc: string;
  kolejnosc?: number;
}

export interface SaveConsentsBodyDto {
  consent_email: boolean;
  consent_privacy: boolean;
  consent_program: boolean;
  consent_sms: boolean;
  consent_winezja_email: boolean;
  consent_winezja_sms: boolean;
  user_id: string;
}

export interface SendReferralCodeDto {
  source: ReferralSource;
  addinfo?: string;
}

export interface SignupConfirmResponseDto {
  message: string;
  card_number?: string;
}

export interface SmsAuthCodeBodyDto {
  phone: string;
  recaptcha: string;
  siteKey: string;
}

export interface SmsAuthCodeConfirmBodyDto {
  code: string;
  phone: string;
}

export interface SmsRetriverAuthCodeBodyDto {
  hashCode: string;
  phone: string;
  recaptcha: string;
  siteKey: string;
}

export interface SubmitApkErrorDto {
  kategoria: string;
  tresc: string;
}

export interface SyneriseTokenDto {
  token: string;
}

export interface TransactionDto {
  kupione: boolean;
  notatka: string;
  notatka_data: string;
  ocena: number;
  ocena_notatka: string;
  ocenione: boolean;
  polubione: boolean;
  zanotowane: boolean;
  /**
   *
   * - Format: date-time
   */
  kupione_data?: string;
  /**
   *
   * - Format: date-time
   */
  ocena_data?: string;
  /**
   *
   * - Format: date-time
   */
  polubione_data?: string;
}

export interface TransactionHistoryGroupDto {
  /**
   *
   * - Format: date-time
   */
  date: string;
  items: TransactionHistoryItemDto;
  total: number;
}

export interface TransactionHistoryItemDetailsDto {
  date: string;
  id: string;
  products: TransactionHistoryProductListItemDto;
  source: TransactionSource;
  total: number;
  place?: PlaceDto;
}

export interface TransactionHistoryItemDetailsV2Dto {
  date: string;
  id: string;
  items: TransactionHistoryListItemDto;
  source: TransactionSource;
  total: number;
  place?: PlaceDto;
}

export interface TransactionHistoryItemDto {
  id: string;
  source: TransactionSource;
  total: number;
  place?: PlaceDto;
}

export interface TransactionHistoryListItemDto {
  discountedPrice: number;
  name: string;
  points: number;
  quantity: number;
  regularPrice: number;
  totalPrice: number;
  image?: string;
  product?: ProductDto;
}

export interface TransactionHistoryPageDto {
  items: TransactionHistoryGroupDto;
  nextItem?: string;
}

export interface TransactionHistoryProductListItemDto {
  _id: string;
  /**
   *
   * - Format: date-time
   */
  addedAt: string;
  app: boolean;
  averageRating: number;
  canApplyDiscounts: boolean;
  color: string;
  currentPrice: number;
  description: string;
  discountedPrice: number;
  id: number;
  isCurrentPriceSpecial: boolean;
  name: string;
  pimId: string;
  points: number;
  prizes: string;
  published: boolean;
  quantity: number;
  ratingsAmount: number;
  regularPrice: number;
  sommelierRating: string;
  specialDiscount: number;
  stars: number;
  strain: string;
  tags: string;
  tasteProperties: ProductTastePropertiesDto;
  totalPrice: number;
  volume: number;
  alcohol?: number;
  country?: string;
  currentOriginalPrice?: number;
  image?: string;
}

export enum TransactionSource {
  CENTRUM_WINA = "CENTRUM_WINA",
  WINEZJA = "WINEZJA",
}

export interface UpdateConsentsBodyDto {
  consent_email: boolean;
  consent_privacy: boolean;
  consent_program: boolean;
  consent_sms: boolean;
  consent_winezja_email: boolean;
  consent_winezja_sms: boolean;
}

export interface UpdatePreferencesBodyDto {
  cat_1: boolean;
  cat_2: boolean;
  cat_3: boolean;
  cat_4: boolean;
  cat_5: boolean;
  cat_6: boolean;
  cat_7: boolean;
}

export interface UpdateUserBodyDto {
  zip: string;
}

export interface UploadImageBodyDto {
  data: string;
}

export interface UseReferralCodeDto {
  kod: string;
}

export interface UserDetailsDto {
  _id: string;
  active: boolean;
  artykuly: number[];
  confirmed: boolean;
  consent_email: boolean;
  consent_history: string[];
  consent_privacy: boolean;
  consent_program: boolean;
  consent_sms: boolean;
  consent_winezja_email: boolean;
  consent_winezja_sms: boolean;
  crm: boolean;
  crm_batch_2: boolean;
  /**
   *
   * - Format: date-time
   */
  data_modyfikacji: string;
  device_id: string;
  discount: number;
  email_confirmed: boolean;
  id: string;
  imie: string;
  karta: string;
  kod_pocztowy: string;
  login: string;
  login_times: number;
  nazwisko: string;
  phone_confirmed: boolean;
  platform: string;
  polecenia: number;
  preferowany_kanal: string;
  promotions: PromoReferenceDto[];
  punkty: number;
  rate_app: boolean;
  rate_app_message: string;
  rekomendowane: string[];
  telefon: string;
  typ_karty: number;
  typ_karty_opis: string;
  version: string;
  via_onboarding: boolean;
  via_signup: boolean;
  z_polecenia: string;
  z_polecenia_other: string;
  z_polecenia_store_id: number;
  z_polecenia_user_id: string;
  zgoda_program: boolean;
  /**
   *
   * - Format: date-time
   */
  consent_date?: string;
  /**
   *
   * - Format: date-time
   */
  data_instalacji_aplikacji?: string;
  data_pierwszego_logowania?: string;
  /**
   *
   * - Format: date-time
   */
  data_urodzenia?: string;
  email?: string;
  last_login?: string;
  /**
   *
   * - Format: date-time
   */
  punkty_data?: string;
}

export interface UserDto {
  _id: string;
  active: boolean;
  confirmed: boolean;
  consent_email: boolean;
  consent_history: ConsentHistoryDto[];
  consent_privacy: boolean;
  consent_program: boolean;
  consent_sms: boolean;
  consent_winezja_email: boolean;
  consent_winezja_sms: boolean;
  deactivated: boolean;
  email_confirmed: boolean;
  firstname: string;
  id: string;
  karta: string;
  lastname: string;
  manual_active: boolean;
  phone_confirmed: boolean;
  platform: string;
  punkty: number;
  store_id: number;
  store_onboard: boolean;
  telefon: string;
  typ_karty: number;
  typ_karty_opis: string;
  zgoda_call: boolean;
  zgoda_email: boolean;
  zgoda_sms: boolean;
  zip: string;
  data_dodania?: string;
  data_urodzenia?: string;
  email?: string;
  last_login?: string;
}

export interface UserTokenDto {
  token: string;
}

export interface ValidateReferralCodeDto {
  referralCode: string;
}

export interface ValidateTokenDto {
  token: string;
}

export interface ValidateTokenUserDto {
  card: string;
  card_color: string;
  card_type: string;
  /**
   *
   * - Format: date-time
   */
  createdAt: string;
  discount: number;
  id: string;
  phone: string;
  /**
   *
   * - Format: date-time
   */
  appInstalledAt?: string;
  email?: string;
}

export interface VersionCheckDto {
  api_version: string;
  apk_status: ApkStatus;
  message: string;
}

export interface WineDto {
  aplikacja: boolean;
  aromat: string;
  beczka: number;
  budowa: number;
  cena: number;
  cena_rabat: boolean;
  cena_specjalna: boolean;
  /**
   *
   * - Format: date-time
   */
  data_dodania: string;
  gwiazdki: number;
  id: number;
  kolor: string;
  kwasowosc: number;
  liczba_glosow: number;
  musujace: boolean;
  nagrody: string;
  nazwa: string;
  obrazek: string;
  ocena_sommeliera: string;
  opis: string;
  owocowe: boolean;
  pim_id: string;
  pojemnosc: number;
  published: boolean;
  rabat_specjalny: number;
  rabaty_nie: boolean;
  smak: string;
  spokojne: boolean;
  srednia_ocen: number;
  suma_ocen: number;
  szczep: string;
  tags: string;
  tanicznosc: number;
  usta: number;
  usta_opis: string;
  wzmocnione: boolean;
  alkohol?: number;
  cena_oryginalna?: number;
  image_url?: string;
  kraj?: string;
  kraj_iso?: string;
  thumbnail_url?: string;
}

export interface WineSearchResultDto {
  hits: WineDto[];
  processingTimeMs: number;
  query: string;
}
