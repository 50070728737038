import { ArrowBendUpLeft, X } from 'phosphor-react';
import Logo from '../assets/Logo';
interface HeaderProps {
  onGoBack?: VoidFunction;
  onExit?: VoidFunction;
}
export const Header: React.FC<HeaderProps> = ({ onGoBack, onExit }) => {
  return (
    <div className="flex justify-center relative px-5 pt-20 sm:pt-5 lg:pt-0 lg:px-0">
      {onExit && (
        <button onClick={onExit} className="absolute top-5 left-5 lg:-left-3">
          <X className="w-8 h-8" weight="bold" color="white" />
        </button>
      )}
      {onGoBack && (
        <button onClick={onGoBack} className="absolute top-5 left-5 lg:-left-3">
          <ArrowBendUpLeft className="w-8 h-8" weight="bold" color="white" />
        </button>
      )}
      <img src="/header.png" className='mb-2 mt-5' />
    </div>
  );
};
