import { Fragment, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useGetPlaces, usePostOnboardingSignup } from '../services-hooks/hooks';
import { Listbox, Portal, Transition } from '@headlessui/react';
import { CaretDown } from 'phosphor-react';
import {
  LoginResponseDto,
  OnboardingDto,
  ReferralSource,
} from '../services-hooks/types';
import { Button } from './ui/button.component';
import { Input } from './ui/input.component';
import { differenceInYears, parseISO } from 'date-fns';
import { Header } from './header.component';

interface SignupProps {
  onboarding: OnboardingDto;
  onSessionStart: (loginResponse: LoginResponseDto) => void;
}

interface SignupFormValue {
  birthdate: string;
  consent_email: boolean;
  consent_privacy: boolean;
  consent_program: boolean;
  consent_sms: boolean;
  consent_winezja_email: boolean;
  consent_winezja_sms: boolean;
  firstname: string;
  lastname: string;
  // referral: string;
  zip: string;
  // referral_addinfo: string;
}
const referralSources = [
  { name: 'Sklep Centrum Wina', value: ReferralSource.store },
  { name: 'Znajomy', value: ReferralSource.user },
  { name: 'Inny', value: ReferralSource.other },
];
export const Signup: React.FC<SignupProps> = ({
  onboarding,
  onSessionStart,
}) => {
  // const [selectedReferralSource, setSelectedReferralSource] = useState(
  //   referralSources[0],
  // );
  const [showErrorToast, setShowErrorToast] = useState(false);
  const { register, formState, control, watch, resetField, handleSubmit } =
    useForm<SignupFormValue>();
  // useEffect(() => {
  //   resetField('referral_addinfo');
  // }, [selectedReferralSource]);
  const { data } = useGetPlaces();
  const { mutateAsync, isLoading } = usePostOnboardingSignup();
  const onSubmit = async (value: SignupFormValue) => {
    try {
      const response = await mutateAsync({
        requestBody: {
          onboardingId: onboarding.id,
          birth: value.birthdate,
          consent_email: value.consent_email,
          consent_privacy: value.consent_privacy,
          consent_program: value.consent_program,
          consent_sms: value.consent_sms,
          consent_winezja_email: false,
          consent_winezja_sms: false,
          firstname: value.firstname,
          lastName: value.lastname,
          referral: {
            source: ReferralSource.winezja,
          },
          zip: value.zip,
          source: 'WINEZJA',
        },
      });
      onSessionStart(response);
    } catch (e) {
      setShowErrorToast(true);
      setTimeout(() => {
        setShowErrorToast(false);
      }, 3000);
    }
  };
  const places = data || [];
  return (
    <>
      <Header />
      <div className="p-5">
        <form onSubmit={handleSubmit(onSubmit)}>
          <p className="text-white text-xl font-bold text-center max-w-lg mx-auto">
            Uzupełnij potrzebne dane aby w pełni korzystać z możliwości Klubu
            Kocham Wino
          </p>
          <Input
            label="Imię"
            placeholder="np. Jan"
            className="mt-5"
            required
            {...register('firstname', { required: true })}
            error={formState.errors.firstname && 'Imię jest wymagane'}
          />
          <Input
            label="Nazwisko"
            placeholder="np. Kowalski"
            className="mt-5"
            required
            {...register('lastname', { required: true })}
            error={formState.errors.lastname && 'Nazwisko jest wymagane'}
          />
          <Input
            label="Data urodzenia"
            placeholder="DD-MM-YYYY"
            className="mt-5"
            required
            type="date"
            {...register('birthdate', {
              required: true,
              validate: (date) => {
                return differenceInYears(new Date(), new Date(date)) < 18
                  ? 'Wymagany wiek to 18+'
                  : true;
              },
            })}
            error={
              formState.errors.birthdate
                ? formState.errors.birthdate.type === 'required'
                  ? 'Data urodzenia jest wymagana'
                  : formState.errors.birthdate?.message
                : null
            }
          />
          <Input
            label="Kod pocztowy"
            placeholder="00-000"
            className="mt-5"
            required
            {...register('zip', {
              required: true,
              pattern: {
                value: /^\d{2}-\d{3}$/,
                message: 'Niepoprawny kod pocztowy',
              },
            })}
            error={
              formState.errors.zip &&
              (formState.errors.zip.message || 'Kod pocztowy jest wymagany')
            }
          />
          {/* 
          <hr className="h-px my-8 bg-gray-200 border-0"></hr>
          <Listbox
            value={selectedReferralSource}
            onChange={setSelectedReferralSource}
          >
            <div className="relative mt-1">
              <p className="mb-2 font-bold text-white">
                Skąd dowiedziałeś się o aplikacji Kocham Wino?
              </p>
              <Listbox.Button className="relative w-full cursor-default rounded-lg bg-white p-3 pr-10 text-left shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-primary-300 font-bold text-white sm:text-xl">
                <span className="block truncate">
                  {selectedReferralSource.name}
                </span>
                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                  <CaretDown />
                </span>
              </Listbox.Button>
              <Transition
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm z-10">
                  {referralSources.map((referralSource, referralIdx) => (
                    <Listbox.Option
                      key={`${referralSource.value}-${referralIdx}`}
                      className={({ active }) =>
                        `relative cursor-default select-none py-2 sm:text-lg px-4 ${
                          active ? 'bg-gray-300 text-white' : 'text-white'
                        }`
                      }
                      value={referralSource}
                    >
                      {({ selected }) => (
                        <>
                          <span
                            className={`block truncate ${
                              selected ? 'font-medium' : 'font-normal'
                            }`}
                          >
                            {referralSource.name}
                          </span>
                        </>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </Listbox>
     
          {selectedReferralSource.value === ReferralSource.store ? (
            <Controller
              control={control}
              name={'referral_addinfo'}
              rules={{ required: true }}
              render={({ field }) => {
                return (
                  <>
                    <Listbox value={field.value} onChange={field.onChange}>
                      <div className="relative mt-5">
                        <Listbox.Button className="relative w-full cursor-default rounded-lg bg-white p-3 pr-10 text-left shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-primary-300 font-bold text-white sm:text-xl">
                          <span className="block truncate">
                            {places.find(
                              (place) => place.id.toString() === field.value,
                            )?.nazwa || 'Wybierz sklep polecający'}
                          </span>
                          <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                            <CaretDown />
                          </span>
                        </Listbox.Button>
                        <Transition
                          as={Fragment}
                          leave="transition ease-in duration-100"
                          leaveFrom="opacity-100"
                          leaveTo="opacity-0"
                        >
                          <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm z-10">
                            {places.map((place) => (
                              <Listbox.Option
                                key={`${place.id}`}
                                className={({ active }) =>
                                  `relative cursor-default select-none py-2 sm:text-lg px-4 ${
                                    active
                                      ? 'bg-gray-300 text-white'
                                      : 'text-white'
                                  }`
                                }
                                value={place.id.toString()}
                              >
                                {({ selected }) => (
                                  <>
                                    <span
                                      className={`block truncate ${
                                        selected ? 'font-medium' : 'font-normal'
                                      }`}
                                    >
                                      {place.nazwa}
                                    </span>
                                  </>
                                )}
                              </Listbox.Option>
                            ))}
                          </Listbox.Options>
                        </Transition>
                      </div>
                    </Listbox>
                    {formState.errors.referral_addinfo && (
                      <span className="error-message mt-2 inline-block w-full text-danger rounded-md">
                        Wybierz sklep polecający
                      </span>
                    )}
                  </>
                );
              }}
            />
          ) : (
            <Input
              placeholder={
                selectedReferralSource.value === ReferralSource.other
                  ? 'Podaj inne źródło'
                  : 'Podaj kod polecający'
              }
              className="mt-5"
              {...register('referral_addinfo', { required: true })}
              error={
                formState.errors.referral_addinfo &&
                selectedReferralSource.value === ReferralSource.other
                  ? 'Wprowadź inne źródło'
                  : 'Wprowadź kod polecający'
              }
            />
          )} */}
          <div className="max-w-sm mx-auto">
            <hr className="h-px my-8 bg-gray-200 border-0 opacity-20"></hr>

            <div className="flex items-center mt-4">
              <input
                id="consent_privacy-checkbox"
                type="checkbox"
                {...register('consent_privacy', { required: true })}
                className="h-5 w-5 rounded border-white border-1 ring-1 ring-white bg-transparent checked:border-white text-red focus:ring-0 hover:ring-white"
              />
              <label
                htmlFor="consent_privacy-checkbox"
                className="ml-2 text-sm font-medium text-white"
              >
                Akceptuję postanowienia{' '}
                <a
                  className="underline"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://centrumwina.pl/polityka-prywatnosci-aplikacji-kocham-wino/"
                >
                  Polityki Prywatności
                </a>{' '}
                *
              </label>
            </div>
            {formState.errors.consent_privacy && (
              <div className="flex">
                <span className="error-message mt-6 py-1 px-4 text-sm font-bold text-white block rounded-full bg-danger text-center">
                  Zaakceptuj politykę prywatności
                </span>
              </div>
            )}
            <div className="flex items-center mt-4">
              <input
                id="consent_program-checkbox"
                type="checkbox"
                {...register('consent_program', { required: true })}
                className="h-5 w-5 rounded border-white border-1 ring-1 ring-white bg-transparent checked:border-white text-red focus:ring-0 hover:ring-white"
              />
              <label
                htmlFor="consent_program-checkbox"
                className="ml-2 text-sm font-medium text-white"
              >
                Zapoznałam(em) się z{' '}
                <a
                  target="_blank"
                  className="underline"
                  rel="noopener noreferrer"
                  href="https://centrumwina.pl/regulamin-programu-kocham-wino/"
                >
                  Regulaminem Klubu Kocham Wino
                </a>{' '}
                i akceptuję jego treść.*
              </label>
            </div>
            {formState.errors.consent_program && (
              <div className="flex">
                <span className="error-message mt-6 py-1 px-4 text-sm font-bold text-white block rounded-full bg-danger text-center">
                  Zaakceptuj zgodę na udział w programie
                </span>
              </div>
            )}
            <p className="mt-4 text-white">
              Chcę otrzymywać informacje o ofertach, rabatach i konkursach od
              Kocham Wino za pośrednictwem:
            </p>
            <div className="flex items-center ml-4 mt-4">
              <input
                id="consent_sms-checkbox"
                type="checkbox"
                {...register('consent_sms')}
                className="h-5 w-5 rounded border-white border-1 ring-1 ring-white bg-transparent checked:border-white text-red focus:ring-0 hover:ring-white"
              />
              <label
                htmlFor="consent_sms-checkbox"
                className="ml-2 text-sm font-medium text-white"
              >
                SMS/MMS
              </label>
            </div>
            <div className="flex items-center ml-4 mt-4">
              <input
                id="consent_email-checkbox"
                type="checkbox"
                {...register('consent_email')}
                className="h-5 w-5 rounded border-white border-1 ring-1 ring-white bg-transparent checked:border-white text-red focus:ring-0 hover:ring-white"
              />
              <label
                htmlFor="consent_email-checkbox"
                className="ml-2 text-sm font-medium text-white"
              >
                Poczty elektronicznej
              </label>
            </div>
            <div className="mt-8">
              <span className="opacity-60 text-white">* Pola obowiązkowe</span>
            </div>
          </div>

          <Button loading={isLoading} className="mt-5" disabled={isLoading}>
            Dalej
          </Button>
        </form>
      </div>
      <Portal>
        <div className="fixed inset-0 pointer-events-none">
          <Transition
            as={Fragment}
            show={showErrorToast}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <div className="bg-danger mt-3 mx-auto w-screen transform max-w-sm shadow-sm p-2 rounded-md">
              <p className="text-white text-center">
                Nie udało się stworzyć konta, spróbuj ponownie
              </p>
            </div>
          </Transition>
        </div>
      </Portal>
    </>
  );
};
