//@ts-nocheck
/**
 * AUTO_GENERATED Do not change this file directly, use config.ts file instead
 *
 * @version 6
 */

import { AxiosRequestConfig } from "axios";
import {
  UseQueryOptions,
  useQuery,
  useMutation,
  UseMutationOptions,
  QueryClient,
  QueryKey,
} from "@tanstack/react-query";
import { RequestError, SwaggerResponse } from "./config";

import type {
  AlcoholFiltersInfoDto,
  AlcoholSearchResultDto,
  ApkBugDto,
  ArticleCategoryDto,
  ArticleDto,
  AuthCodeSendResponseDto,
  CardDto,
  CheckVersionBodyDto,
  CommentDto,
  CommentWineBodyDto,
  ConfirmSignupDto,
  ConsentDto,
  CreateNotificationBodyDto,
  DeleteNotificationBodyDto,
  DeviceSetupBodyDto,
  EmailAuthCodeConfirmBodyDto,
  EmailChangeBodyDto,
  EmailChangeConfirmBodyDto,
  EmailConfirmResponseDto,
  GatherImageDataBodyDto,
  GetProductFiltersAlcoholQueryParams,
  GetTransactionHistoryCardQueryParams,
  GetTransactionHistoryDetailsCardQueryParams,
  GetTransactionHistoryDetailsQueryParams,
  GetTransactionHistoryDetailsV2CardQueryParams,
  GetTransactionHistoryDetailsV2QueryParams,
  GetTransactionHistoryQueryParams,
  GetTransactionHistoryV2CardQueryParams,
  GetTransactionHistoryV2QueryParams,
  KeycloakLoginResponseDto,
  LoginBodyDto,
  LoginClientCredentialsBodyDto,
  LoginResponseDto,
  MarketingCommunicationsBodyDto,
  MessageDto,
  NotificationDto,
  OnboardAuthDto,
  OnboardingDto,
  OnboardingEmailAuthCodeConfirmBodyDto,
  OnboardingEmailAuthCodeSendBodyDto,
  OnboardingEmailValidateBodyDto,
  OnboardingSignupBodyDto,
  PasswordChangeBodyDto,
  PasswordRemindBodyDto,
  PasswordRemindResponseDto,
  PhoneChangeBodyDto,
  PhoneChangeConfirmBodyDto,
  PhoneConfirmResponseDto,
  PlaceDto,
  PrivacyPolicyDto,
  ProductDto,
  ProductFiltersInfoDto,
  ProductSearchBodyDto,
  ProductSearchResultDto,
  ProductV2Dto,
  ProductWithTransactionDto,
  ProductWithTransactionV2Dto,
  PromotionSummaryDto,
  RateWineBodyDto,
  ReferralDto,
  RefreshTokenBodyDto,
  RefreshTokenResponseDto,
  RegulationDto,
  SaveConsentsBodyDto,
  SignupConfirmResponseDto,
  SmsAuthCodeBodyDto,
  SmsAuthCodeConfirmBodyDto,
  SmsRetriverAuthCodeBodyDto,
  SubmitApkErrorDto,
  TransactionDto,
  TransactionHistoryItemDetailsDto,
  TransactionHistoryItemDetailsV2Dto,
  TransactionHistoryPageDto,
  UpdateConsentsBodyDto,
  UpdatePreferencesBodyDto,
  UpdateUserBodyDto,
  UploadImageBodyDto,
  UseReferralCodeDto,
  UserDetailsDto,
  UserDto,
  UserTokenDto,
  ValidateReferralCodeDto,
  ValidateTokenDto,
  ValidateTokenUserDto,
  VersionCheckDto,
  WineSearchResultDto,
} from "./types";
import {
  deleteAccount,
  deleteNotNotificationsId,
  getApkErrorlist,
  getArticles,
  getArticlesCategory,
  getArticlesCategoryId,
  getArticlesCathegory,
  getArticlesCathegoryId,
  getArticlesId,
  getArticlesIdId,
  getArticlesUser,
  getCards,
  getConsents,
  getNotNotifications,
  getPlaces,
  getPolicies,
  getPoliciesLatest,
  getProduct,
  getProductCommentsId,
  getProductFilters,
  getProductFiltersAlcohol,
  getProductFiltersWine,
  getProductId,
  getProductV2,
  getProductV2Id,
  getPromosUser,
  getReferalGenerate,
  getReferralGenerate,
  getRegulations,
  getRegulationsTitles,
  getSyneriseToken,
  getSyneriseVerify_token,
  getTransactionHistory,
  getTransactionHistoryCard,
  getTransactionHistoryDetails,
  getTransactionHistoryDetailsCard,
  getTransactionHistoryDetailsV2,
  getTransactionHistoryDetailsV2Card,
  getTransactionHistoryV2,
  getTransactionHistoryV2Card,
  getTransactions,
  getTransactionsCommented,
  getTransactionsLiked,
  getTransactionsPurchased,
  getTransactionsRated,
  getTransactionsRecent,
  getTransactionsRecommended,
  getTransactionsV2,
  getTransactionsV2Commented,
  getTransactionsV2Liked,
  getTransactionsV2Purchased,
  getTransactionsV2Rated,
  getTransactionsV2Recent,
  getTransactionsV2Recommended,
  getUserDetails,
  getUserLikeId,
  getUserProducts,
  getUserProductsId,
  getUserUnlikeId,
  getUserV2Products,
  postAccountAppOpened,
  postAccountAuthcodeConfirmEmail,
  postAccountAuthcodeEmail,
  postAccountConfirm,
  postAccountConfirmonboard,
  postAccountEmailConfirm,
  postAccountLogin,
  postAccountLoginService,
  postAccountMktgcommunitation,
  postAccountOnboardauth,
  postAccountPasswordchange,
  postAccountPasswordremind,
  postAccountPhoneConfirm,
  postAccountRefresh_token,
  postAccountVersioncheck,
  postApkDevice,
  postApkSubmiterror,
  postApkVersioncheck,
  postConsents,
  postExternalLink,
  postExternalLinkValidate,
  postNotDelnotification,
  postNotNotifications,
  postNotNotificationsAddnotification,
  postOnboardingAuthcodeConfirmEmail,
  postOnboardingAuthcodeConfirmSms,
  postOnboardingAuthcodeEmail,
  postOnboardingAuthcodeRetriverSms,
  postOnboardingAuthcodeSms,
  postOnboardingSignup,
  postOnboardingValidateEmail,
  postPoliciesUpdate,
  postProductSearch,
  postProductSearchAlcohol,
  postProductSearchAlcoholCard,
  postProductSearchCard,
  postProductSearchWine,
  postProductSearchWineCard,
  postReferalUse,
  postReferalValidate,
  postReferralUse,
  postReferralValidate,
  postSearchGatherimagedata,
  postSearchImage,
  postUserApprated,
  postUserComment,
  postUserPreferences,
  postUserRate,
  putAccountEmail,
  putAccountPhone,
  putConsents,
  putUserProfile,
} from "./services";

export type SwaggerTypescriptMutationDefaultParams<TExtra> = {
  _extraVariables?: TExtra;
  configOverride?: AxiosRequestConfig;
};
type SwaggerTypescriptUseQueryOptions<TData> = UseQueryOptions<
  SwaggerResponse<TData>,
  RequestError | Error
>;

type SwaggerTypescriptUseMutationOptions<TData, TRequest, TExtra> =
  UseMutationOptions<
    SwaggerResponse<TData>,
    RequestError | Error,
    TRequest & SwaggerTypescriptMutationDefaultParams<TExtra>
  >;

type SwaggerTypescriptUseMutationOptionsVoid<TData, TExtra> =
  UseMutationOptions<
    SwaggerResponse<TData>,
    RequestError | Error,
    SwaggerTypescriptMutationDefaultParams<TExtra> | void
  >;

export const useDeleteAccount = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptionsVoid<UserDto, TExtra>
) => {
  return useMutation((_o) => {
    const { configOverride } = _o || {};

    return deleteAccount(configOverride);
  }, options);
};

export const useDeleteNotNotificationsId = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    NotificationDto,
    { id: string },
    TExtra
  >
) => {
  return useMutation((_o) => {
    const {
      id,

      configOverride,
    } = _o || {};

    return deleteNotNotificationsId(
      id,

      configOverride
    );
  }, options);
};

export const useGetApkErrorlist = (
  options?: SwaggerTypescriptUseQueryOptions<ApkBugDto[]>,
  configOverride?: AxiosRequestConfig
) => {
  const { key, fun } = useGetApkErrorlist.info(configOverride);
  return useQuery(key, fun, options);
};
useGetApkErrorlist.info = (configOverride?: AxiosRequestConfig) => {
  return {
    key: [getApkErrorlist.key] as QueryKey,
    fun: () => getApkErrorlist(configOverride),
  };
};
useGetApkErrorlist.prefetch = (
  client: QueryClient,
  options?: SwaggerTypescriptUseQueryOptions<ApkBugDto[]>,
  configOverride?: AxiosRequestConfig
) => {
  const { key, fun } = useGetApkErrorlist.info(configOverride);

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery(key, () => fun(), options);
};
export const useGetArticles = (
  options?: SwaggerTypescriptUseQueryOptions<ArticleDto[]>,
  configOverride?: AxiosRequestConfig
) => {
  const { key, fun } = useGetArticles.info(configOverride);
  return useQuery(key, fun, options);
};
useGetArticles.info = (configOverride?: AxiosRequestConfig) => {
  return {
    key: [getArticles.key] as QueryKey,
    fun: () => getArticles(configOverride),
  };
};
useGetArticles.prefetch = (
  client: QueryClient,
  options?: SwaggerTypescriptUseQueryOptions<ArticleDto[]>,
  configOverride?: AxiosRequestConfig
) => {
  const { key, fun } = useGetArticles.info(configOverride);

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery(key, () => fun(), options);
};
export const useGetArticlesCategory = (
  options?: SwaggerTypescriptUseQueryOptions<ArticleCategoryDto[]>,
  configOverride?: AxiosRequestConfig
) => {
  const { key, fun } = useGetArticlesCategory.info(configOverride);
  return useQuery(key, fun, options);
};
useGetArticlesCategory.info = (configOverride?: AxiosRequestConfig) => {
  return {
    key: [getArticlesCategory.key] as QueryKey,
    fun: () => getArticlesCategory(configOverride),
  };
};
useGetArticlesCategory.prefetch = (
  client: QueryClient,
  options?: SwaggerTypescriptUseQueryOptions<ArticleCategoryDto[]>,
  configOverride?: AxiosRequestConfig
) => {
  const { key, fun } = useGetArticlesCategory.info(configOverride);

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery(key, () => fun(), options);
};
export const useGetArticlesCategoryId = (
  id: number,
  options?: SwaggerTypescriptUseQueryOptions<ArticleDto[]>,
  configOverride?: AxiosRequestConfig
) => {
  const { key, fun } = useGetArticlesCategoryId.info(
    id,

    configOverride
  );
  return useQuery(key, fun, options);
};
useGetArticlesCategoryId.info = (
  id: number,
  configOverride?: AxiosRequestConfig
) => {
  return {
    key: [getArticlesCategoryId.key, id] as QueryKey,
    fun: () =>
      getArticlesCategoryId(
        id,

        configOverride
      ),
  };
};
useGetArticlesCategoryId.prefetch = (
  client: QueryClient,
  id: number,
  options?: SwaggerTypescriptUseQueryOptions<ArticleDto[]>,
  configOverride?: AxiosRequestConfig
) => {
  const { key, fun } = useGetArticlesCategoryId.info(
    id,

    configOverride
  );

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery(key, () => fun(), options);
};
export const useGetArticlesCathegory = (
  options?: SwaggerTypescriptUseQueryOptions<ArticleCategoryDto[]>,
  configOverride?: AxiosRequestConfig
) => {
  const { key, fun } = useGetArticlesCathegory.info(configOverride);
  return useQuery(key, fun, options);
};
useGetArticlesCathegory.info = (configOverride?: AxiosRequestConfig) => {
  return {
    key: [getArticlesCathegory.key] as QueryKey,
    fun: () => getArticlesCathegory(configOverride),
  };
};
useGetArticlesCathegory.prefetch = (
  client: QueryClient,
  options?: SwaggerTypescriptUseQueryOptions<ArticleCategoryDto[]>,
  configOverride?: AxiosRequestConfig
) => {
  const { key, fun } = useGetArticlesCathegory.info(configOverride);

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery(key, () => fun(), options);
};
export const useGetArticlesCathegoryId = (
  id: number,
  options?: SwaggerTypescriptUseQueryOptions<ArticleDto[]>,
  configOverride?: AxiosRequestConfig
) => {
  const { key, fun } = useGetArticlesCathegoryId.info(
    id,

    configOverride
  );
  return useQuery(key, fun, options);
};
useGetArticlesCathegoryId.info = (
  id: number,
  configOverride?: AxiosRequestConfig
) => {
  return {
    key: [getArticlesCathegoryId.key, id] as QueryKey,
    fun: () =>
      getArticlesCathegoryId(
        id,

        configOverride
      ),
  };
};
useGetArticlesCathegoryId.prefetch = (
  client: QueryClient,
  id: number,
  options?: SwaggerTypescriptUseQueryOptions<ArticleDto[]>,
  configOverride?: AxiosRequestConfig
) => {
  const { key, fun } = useGetArticlesCathegoryId.info(
    id,

    configOverride
  );

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery(key, () => fun(), options);
};
export const useGetArticlesId = (
  id: number,
  options?: SwaggerTypescriptUseQueryOptions<ArticleDto>,
  configOverride?: AxiosRequestConfig
) => {
  const { key, fun } = useGetArticlesId.info(
    id,

    configOverride
  );
  return useQuery(key, fun, options);
};
useGetArticlesId.info = (id: number, configOverride?: AxiosRequestConfig) => {
  return {
    key: [getArticlesId.key, id] as QueryKey,
    fun: () =>
      getArticlesId(
        id,

        configOverride
      ),
  };
};
useGetArticlesId.prefetch = (
  client: QueryClient,
  id: number,
  options?: SwaggerTypescriptUseQueryOptions<ArticleDto>,
  configOverride?: AxiosRequestConfig
) => {
  const { key, fun } = useGetArticlesId.info(
    id,

    configOverride
  );

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery(key, () => fun(), options);
};
export const useGetArticlesIdId = (
  id: number,
  options?: SwaggerTypescriptUseQueryOptions<ArticleDto>,
  configOverride?: AxiosRequestConfig
) => {
  const { key, fun } = useGetArticlesIdId.info(
    id,

    configOverride
  );
  return useQuery(key, fun, options);
};
useGetArticlesIdId.info = (id: number, configOverride?: AxiosRequestConfig) => {
  return {
    key: [getArticlesIdId.key, id] as QueryKey,
    fun: () =>
      getArticlesIdId(
        id,

        configOverride
      ),
  };
};
useGetArticlesIdId.prefetch = (
  client: QueryClient,
  id: number,
  options?: SwaggerTypescriptUseQueryOptions<ArticleDto>,
  configOverride?: AxiosRequestConfig
) => {
  const { key, fun } = useGetArticlesIdId.info(
    id,

    configOverride
  );

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery(key, () => fun(), options);
};
export const useGetArticlesUser = (
  options?: SwaggerTypescriptUseQueryOptions<ArticleDto>,
  configOverride?: AxiosRequestConfig
) => {
  const { key, fun } = useGetArticlesUser.info(configOverride);
  return useQuery(key, fun, options);
};
useGetArticlesUser.info = (configOverride?: AxiosRequestConfig) => {
  return {
    key: [getArticlesUser.key] as QueryKey,
    fun: () => getArticlesUser(configOverride),
  };
};
useGetArticlesUser.prefetch = (
  client: QueryClient,
  options?: SwaggerTypescriptUseQueryOptions<ArticleDto>,
  configOverride?: AxiosRequestConfig
) => {
  const { key, fun } = useGetArticlesUser.info(configOverride);

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery(key, () => fun(), options);
};
export const useGetCards = (
  options?: SwaggerTypescriptUseQueryOptions<CardDto[]>,
  configOverride?: AxiosRequestConfig
) => {
  const { key, fun } = useGetCards.info(configOverride);
  return useQuery(key, fun, options);
};
useGetCards.info = (configOverride?: AxiosRequestConfig) => {
  return {
    key: [getCards.key] as QueryKey,
    fun: () => getCards(configOverride),
  };
};
useGetCards.prefetch = (
  client: QueryClient,
  options?: SwaggerTypescriptUseQueryOptions<CardDto[]>,
  configOverride?: AxiosRequestConfig
) => {
  const { key, fun } = useGetCards.info(configOverride);

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery(key, () => fun(), options);
};
export const useGetConsents = (
  options?: SwaggerTypescriptUseQueryOptions<ConsentDto[]>,
  configOverride?: AxiosRequestConfig
) => {
  const { key, fun } = useGetConsents.info(configOverride);
  return useQuery(key, fun, options);
};
useGetConsents.info = (configOverride?: AxiosRequestConfig) => {
  return {
    key: [getConsents.key] as QueryKey,
    fun: () => getConsents(configOverride),
  };
};
useGetConsents.prefetch = (
  client: QueryClient,
  options?: SwaggerTypescriptUseQueryOptions<ConsentDto[]>,
  configOverride?: AxiosRequestConfig
) => {
  const { key, fun } = useGetConsents.info(configOverride);

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery(key, () => fun(), options);
};
export const useGetNotNotifications = (
  options?: SwaggerTypescriptUseQueryOptions<NotificationDto[]>,
  configOverride?: AxiosRequestConfig
) => {
  const { key, fun } = useGetNotNotifications.info(configOverride);
  return useQuery(key, fun, options);
};
useGetNotNotifications.info = (configOverride?: AxiosRequestConfig) => {
  return {
    key: [getNotNotifications.key] as QueryKey,
    fun: () => getNotNotifications(configOverride),
  };
};
useGetNotNotifications.prefetch = (
  client: QueryClient,
  options?: SwaggerTypescriptUseQueryOptions<NotificationDto[]>,
  configOverride?: AxiosRequestConfig
) => {
  const { key, fun } = useGetNotNotifications.info(configOverride);

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery(key, () => fun(), options);
};
export const useGetPlaces = (
  options?: SwaggerTypescriptUseQueryOptions<PlaceDto[]>,
  configOverride?: AxiosRequestConfig
) => {
  const { key, fun } = useGetPlaces.info(configOverride);
  return useQuery(key, fun, options);
};
useGetPlaces.info = (configOverride?: AxiosRequestConfig) => {
  return {
    key: [getPlaces.key] as QueryKey,
    fun: () => getPlaces(configOverride),
  };
};
useGetPlaces.prefetch = (
  client: QueryClient,
  options?: SwaggerTypescriptUseQueryOptions<PlaceDto[]>,
  configOverride?: AxiosRequestConfig
) => {
  const { key, fun } = useGetPlaces.info(configOverride);

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery(key, () => fun(), options);
};
export const useGetPolicies = (
  options?: SwaggerTypescriptUseQueryOptions<PrivacyPolicyDto[]>,
  configOverride?: AxiosRequestConfig
) => {
  const { key, fun } = useGetPolicies.info(configOverride);
  return useQuery(key, fun, options);
};
useGetPolicies.info = (configOverride?: AxiosRequestConfig) => {
  return {
    key: [getPolicies.key] as QueryKey,
    fun: () => getPolicies(configOverride),
  };
};
useGetPolicies.prefetch = (
  client: QueryClient,
  options?: SwaggerTypescriptUseQueryOptions<PrivacyPolicyDto[]>,
  configOverride?: AxiosRequestConfig
) => {
  const { key, fun } = useGetPolicies.info(configOverride);

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery(key, () => fun(), options);
};
export const useGetPoliciesLatest = (
  options?: SwaggerTypescriptUseQueryOptions<PrivacyPolicyDto[]>,
  configOverride?: AxiosRequestConfig
) => {
  const { key, fun } = useGetPoliciesLatest.info(configOverride);
  return useQuery(key, fun, options);
};
useGetPoliciesLatest.info = (configOverride?: AxiosRequestConfig) => {
  return {
    key: [getPoliciesLatest.key] as QueryKey,
    fun: () => getPoliciesLatest(configOverride),
  };
};
useGetPoliciesLatest.prefetch = (
  client: QueryClient,
  options?: SwaggerTypescriptUseQueryOptions<PrivacyPolicyDto[]>,
  configOverride?: AxiosRequestConfig
) => {
  const { key, fun } = useGetPoliciesLatest.info(configOverride);

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery(key, () => fun(), options);
};
export const useGetProduct = (
  options?: SwaggerTypescriptUseQueryOptions<ProductDto[]>,
  configOverride?: AxiosRequestConfig
) => {
  const { key, fun } = useGetProduct.info(configOverride);
  return useQuery(key, fun, options);
};
useGetProduct.info = (configOverride?: AxiosRequestConfig) => {
  return {
    key: [getProduct.key] as QueryKey,
    fun: () => getProduct(configOverride),
  };
};
useGetProduct.prefetch = (
  client: QueryClient,
  options?: SwaggerTypescriptUseQueryOptions<ProductDto[]>,
  configOverride?: AxiosRequestConfig
) => {
  const { key, fun } = useGetProduct.info(configOverride);

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery(key, () => fun(), options);
};
export const useGetProductCommentsId = (
  id: string,
  options?: SwaggerTypescriptUseQueryOptions<CommentDto[]>,
  configOverride?: AxiosRequestConfig
) => {
  const { key, fun } = useGetProductCommentsId.info(
    id,

    configOverride
  );
  return useQuery(key, fun, options);
};
useGetProductCommentsId.info = (
  id: string,
  configOverride?: AxiosRequestConfig
) => {
  return {
    key: [getProductCommentsId.key, id] as QueryKey,
    fun: () =>
      getProductCommentsId(
        id,

        configOverride
      ),
  };
};
useGetProductCommentsId.prefetch = (
  client: QueryClient,
  id: string,
  options?: SwaggerTypescriptUseQueryOptions<CommentDto[]>,
  configOverride?: AxiosRequestConfig
) => {
  const { key, fun } = useGetProductCommentsId.info(
    id,

    configOverride
  );

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery(key, () => fun(), options);
};

/**
 * @deprecated This endpoint deprecated and will be remove. Please use an alternative
 */
export const useGetProductFilters = (
  options?: SwaggerTypescriptUseQueryOptions<ProductFiltersInfoDto>,
  configOverride?: AxiosRequestConfig
) => {
  const { key, fun } = useGetProductFilters.info(configOverride);
  return useQuery(key, fun, options);
};
useGetProductFilters.info = (configOverride?: AxiosRequestConfig) => {
  return {
    key: [getProductFilters.key] as QueryKey,
    fun: () => getProductFilters(configOverride),
  };
};
useGetProductFilters.prefetch = (
  client: QueryClient,
  options?: SwaggerTypescriptUseQueryOptions<ProductFiltersInfoDto>,
  configOverride?: AxiosRequestConfig
) => {
  const { key, fun } = useGetProductFilters.info(configOverride);

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery(key, () => fun(), options);
};
export const useGetProductFiltersAlcohol = (
  queryParams: GetProductFiltersAlcoholQueryParams,
  options?: SwaggerTypescriptUseQueryOptions<AlcoholFiltersInfoDto>,
  configOverride?: AxiosRequestConfig
) => {
  const { key, fun } = useGetProductFiltersAlcohol.info(
    queryParams,
    configOverride
  );
  return useQuery(key, fun, options);
};
useGetProductFiltersAlcohol.info = (
  queryParams: GetProductFiltersAlcoholQueryParams,
  configOverride?: AxiosRequestConfig
) => {
  return {
    key: [getProductFiltersAlcohol.key, queryParams] as QueryKey,
    fun: () =>
      getProductFiltersAlcohol(
        queryParams,

        configOverride
      ),
  };
};
useGetProductFiltersAlcohol.prefetch = (
  client: QueryClient,
  queryParams: GetProductFiltersAlcoholQueryParams,
  options?: SwaggerTypescriptUseQueryOptions<AlcoholFiltersInfoDto>,
  configOverride?: AxiosRequestConfig
) => {
  const { key, fun } = useGetProductFiltersAlcohol.info(
    queryParams,
    configOverride
  );

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery(key, () => fun(), options);
};
export const useGetProductFiltersWine = (
  options?: SwaggerTypescriptUseQueryOptions<ProductFiltersInfoDto>,
  configOverride?: AxiosRequestConfig
) => {
  const { key, fun } = useGetProductFiltersWine.info(configOverride);
  return useQuery(key, fun, options);
};
useGetProductFiltersWine.info = (configOverride?: AxiosRequestConfig) => {
  return {
    key: [getProductFiltersWine.key] as QueryKey,
    fun: () => getProductFiltersWine(configOverride),
  };
};
useGetProductFiltersWine.prefetch = (
  client: QueryClient,
  options?: SwaggerTypescriptUseQueryOptions<ProductFiltersInfoDto>,
  configOverride?: AxiosRequestConfig
) => {
  const { key, fun } = useGetProductFiltersWine.info(configOverride);

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery(key, () => fun(), options);
};
export const useGetProductId = (
  id: string,
  options?: SwaggerTypescriptUseQueryOptions<ProductDto>,
  configOverride?: AxiosRequestConfig
) => {
  const { key, fun } = useGetProductId.info(
    id,

    configOverride
  );
  return useQuery(key, fun, options);
};
useGetProductId.info = (id: string, configOverride?: AxiosRequestConfig) => {
  return {
    key: [getProductId.key, id] as QueryKey,
    fun: () =>
      getProductId(
        id,

        configOverride
      ),
  };
};
useGetProductId.prefetch = (
  client: QueryClient,
  id: string,
  options?: SwaggerTypescriptUseQueryOptions<ProductDto>,
  configOverride?: AxiosRequestConfig
) => {
  const { key, fun } = useGetProductId.info(
    id,

    configOverride
  );

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery(key, () => fun(), options);
};
export const useGetProductV2 = (
  options?: SwaggerTypescriptUseQueryOptions<ProductV2Dto[]>,
  configOverride?: AxiosRequestConfig
) => {
  const { key, fun } = useGetProductV2.info(configOverride);
  return useQuery(key, fun, options);
};
useGetProductV2.info = (configOverride?: AxiosRequestConfig) => {
  return {
    key: [getProductV2.key] as QueryKey,
    fun: () => getProductV2(configOverride),
  };
};
useGetProductV2.prefetch = (
  client: QueryClient,
  options?: SwaggerTypescriptUseQueryOptions<ProductV2Dto[]>,
  configOverride?: AxiosRequestConfig
) => {
  const { key, fun } = useGetProductV2.info(configOverride);

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery(key, () => fun(), options);
};
export const useGetProductV2Id = (
  id: string,
  options?: SwaggerTypescriptUseQueryOptions<ProductV2Dto>,
  configOverride?: AxiosRequestConfig
) => {
  const { key, fun } = useGetProductV2Id.info(
    id,

    configOverride
  );
  return useQuery(key, fun, options);
};
useGetProductV2Id.info = (id: string, configOverride?: AxiosRequestConfig) => {
  return {
    key: [getProductV2Id.key, id] as QueryKey,
    fun: () =>
      getProductV2Id(
        id,

        configOverride
      ),
  };
};
useGetProductV2Id.prefetch = (
  client: QueryClient,
  id: string,
  options?: SwaggerTypescriptUseQueryOptions<ProductV2Dto>,
  configOverride?: AxiosRequestConfig
) => {
  const { key, fun } = useGetProductV2Id.info(
    id,

    configOverride
  );

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery(key, () => fun(), options);
};
export const useGetPromosUser = (
  options?: SwaggerTypescriptUseQueryOptions<PromotionSummaryDto>,
  configOverride?: AxiosRequestConfig
) => {
  const { key, fun } = useGetPromosUser.info(configOverride);
  return useQuery(key, fun, options);
};
useGetPromosUser.info = (configOverride?: AxiosRequestConfig) => {
  return {
    key: [getPromosUser.key] as QueryKey,
    fun: () => getPromosUser(configOverride),
  };
};
useGetPromosUser.prefetch = (
  client: QueryClient,
  options?: SwaggerTypescriptUseQueryOptions<PromotionSummaryDto>,
  configOverride?: AxiosRequestConfig
) => {
  const { key, fun } = useGetPromosUser.info(configOverride);

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery(key, () => fun(), options);
};
export const useGetReferalGenerate = (
  options?: SwaggerTypescriptUseQueryOptions<any>,
  configOverride?: AxiosRequestConfig
) => {
  const { key, fun } = useGetReferalGenerate.info(configOverride);
  return useQuery(key, fun, options);
};
useGetReferalGenerate.info = (configOverride?: AxiosRequestConfig) => {
  return {
    key: [getReferalGenerate.key] as QueryKey,
    fun: () => getReferalGenerate(configOverride),
  };
};
useGetReferalGenerate.prefetch = (
  client: QueryClient,
  options?: SwaggerTypescriptUseQueryOptions<any>,
  configOverride?: AxiosRequestConfig
) => {
  const { key, fun } = useGetReferalGenerate.info(configOverride);

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery(key, () => fun(), options);
};
export const useGetReferralGenerate = (
  options?: SwaggerTypescriptUseQueryOptions<any>,
  configOverride?: AxiosRequestConfig
) => {
  const { key, fun } = useGetReferralGenerate.info(configOverride);
  return useQuery(key, fun, options);
};
useGetReferralGenerate.info = (configOverride?: AxiosRequestConfig) => {
  return {
    key: [getReferralGenerate.key] as QueryKey,
    fun: () => getReferralGenerate(configOverride),
  };
};
useGetReferralGenerate.prefetch = (
  client: QueryClient,
  options?: SwaggerTypescriptUseQueryOptions<any>,
  configOverride?: AxiosRequestConfig
) => {
  const { key, fun } = useGetReferralGenerate.info(configOverride);

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery(key, () => fun(), options);
};
export const useGetRegulations = (
  options?: SwaggerTypescriptUseQueryOptions<RegulationDto[]>,
  configOverride?: AxiosRequestConfig
) => {
  const { key, fun } = useGetRegulations.info(configOverride);
  return useQuery(key, fun, options);
};
useGetRegulations.info = (configOverride?: AxiosRequestConfig) => {
  return {
    key: [getRegulations.key] as QueryKey,
    fun: () => getRegulations(configOverride),
  };
};
useGetRegulations.prefetch = (
  client: QueryClient,
  options?: SwaggerTypescriptUseQueryOptions<RegulationDto[]>,
  configOverride?: AxiosRequestConfig
) => {
  const { key, fun } = useGetRegulations.info(configOverride);

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery(key, () => fun(), options);
};
export const useGetRegulationsTitles = (
  options?: SwaggerTypescriptUseQueryOptions<RegulationDto[]>,
  configOverride?: AxiosRequestConfig
) => {
  const { key, fun } = useGetRegulationsTitles.info(configOverride);
  return useQuery(key, fun, options);
};
useGetRegulationsTitles.info = (configOverride?: AxiosRequestConfig) => {
  return {
    key: [getRegulationsTitles.key] as QueryKey,
    fun: () => getRegulationsTitles(configOverride),
  };
};
useGetRegulationsTitles.prefetch = (
  client: QueryClient,
  options?: SwaggerTypescriptUseQueryOptions<RegulationDto[]>,
  configOverride?: AxiosRequestConfig
) => {
  const { key, fun } = useGetRegulationsTitles.info(configOverride);

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery(key, () => fun(), options);
};
export const useGetSyneriseToken = (
  options?: SwaggerTypescriptUseQueryOptions<any>,
  configOverride?: AxiosRequestConfig
) => {
  const { key, fun } = useGetSyneriseToken.info(configOverride);
  return useQuery(key, fun, options);
};
useGetSyneriseToken.info = (configOverride?: AxiosRequestConfig) => {
  return {
    key: [getSyneriseToken.key] as QueryKey,
    fun: () => getSyneriseToken(configOverride),
  };
};
useGetSyneriseToken.prefetch = (
  client: QueryClient,
  options?: SwaggerTypescriptUseQueryOptions<any>,
  configOverride?: AxiosRequestConfig
) => {
  const { key, fun } = useGetSyneriseToken.info(configOverride);

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery(key, () => fun(), options);
};
export const useGetSyneriseVerify_token = (
  headerParams?: { authorization: string },
  options?: SwaggerTypescriptUseQueryOptions<any>,
  configOverride?: AxiosRequestConfig
) => {
  const { key, fun } = useGetSyneriseVerify_token.info(
    headerParams,
    configOverride
  );
  return useQuery(key, fun, options);
};
useGetSyneriseVerify_token.info = (
  headerParams?: { authorization: string },
  configOverride?: AxiosRequestConfig
) => {
  return {
    key: [getSyneriseVerify_token.key, headerParams] as QueryKey,
    fun: () => getSyneriseVerify_token(headerParams, configOverride),
  };
};
useGetSyneriseVerify_token.prefetch = (
  client: QueryClient,
  headerParams?: { authorization: string },
  options?: SwaggerTypescriptUseQueryOptions<any>,
  configOverride?: AxiosRequestConfig
) => {
  const { key, fun } = useGetSyneriseVerify_token.info(
    headerParams,
    configOverride
  );

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery(key, () => fun(), options);
};
export const useGetTransactionHistory = (
  queryParams?: GetTransactionHistoryQueryParams,
  options?: SwaggerTypescriptUseQueryOptions<TransactionHistoryPageDto>,
  configOverride?: AxiosRequestConfig
) => {
  const { key, fun } = useGetTransactionHistory.info(
    queryParams,
    configOverride
  );
  return useQuery(key, fun, options);
};
useGetTransactionHistory.info = (
  queryParams?: GetTransactionHistoryQueryParams,
  configOverride?: AxiosRequestConfig
) => {
  return {
    key: [getTransactionHistory.key, queryParams] as QueryKey,
    fun: () =>
      getTransactionHistory(
        queryParams,

        configOverride
      ),
  };
};
useGetTransactionHistory.prefetch = (
  client: QueryClient,
  queryParams?: GetTransactionHistoryQueryParams,
  options?: SwaggerTypescriptUseQueryOptions<TransactionHistoryPageDto>,
  configOverride?: AxiosRequestConfig
) => {
  const { key, fun } = useGetTransactionHistory.info(
    queryParams,
    configOverride
  );

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery(key, () => fun(), options);
};
export const useGetTransactionHistoryCard = (
  card: string,
  queryParams?: GetTransactionHistoryCardQueryParams,
  options?: SwaggerTypescriptUseQueryOptions<TransactionHistoryPageDto>,
  configOverride?: AxiosRequestConfig
) => {
  const { key, fun } = useGetTransactionHistoryCard.info(
    card,

    queryParams,
    configOverride
  );
  return useQuery(key, fun, options);
};
useGetTransactionHistoryCard.info = (
  card: string,
  queryParams?: GetTransactionHistoryCardQueryParams,
  configOverride?: AxiosRequestConfig
) => {
  return {
    key: [getTransactionHistoryCard.key, card, queryParams] as QueryKey,
    fun: () =>
      getTransactionHistoryCard(
        card,

        queryParams,

        configOverride
      ),
  };
};
useGetTransactionHistoryCard.prefetch = (
  client: QueryClient,
  card: string,
  queryParams?: GetTransactionHistoryCardQueryParams,
  options?: SwaggerTypescriptUseQueryOptions<TransactionHistoryPageDto>,
  configOverride?: AxiosRequestConfig
) => {
  const { key, fun } = useGetTransactionHistoryCard.info(
    card,

    queryParams,
    configOverride
  );

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery(key, () => fun(), options);
};
export const useGetTransactionHistoryDetails = (
  queryParams: GetTransactionHistoryDetailsQueryParams,
  options?: SwaggerTypescriptUseQueryOptions<
    TransactionHistoryItemDetailsDto[]
  >,
  configOverride?: AxiosRequestConfig
) => {
  const { key, fun } = useGetTransactionHistoryDetails.info(
    queryParams,
    configOverride
  );
  return useQuery(key, fun, options);
};
useGetTransactionHistoryDetails.info = (
  queryParams: GetTransactionHistoryDetailsQueryParams,
  configOverride?: AxiosRequestConfig
) => {
  return {
    key: [getTransactionHistoryDetails.key, queryParams] as QueryKey,
    fun: () =>
      getTransactionHistoryDetails(
        queryParams,

        configOverride
      ),
  };
};
useGetTransactionHistoryDetails.prefetch = (
  client: QueryClient,
  queryParams: GetTransactionHistoryDetailsQueryParams,
  options?: SwaggerTypescriptUseQueryOptions<
    TransactionHistoryItemDetailsDto[]
  >,
  configOverride?: AxiosRequestConfig
) => {
  const { key, fun } = useGetTransactionHistoryDetails.info(
    queryParams,
    configOverride
  );

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery(key, () => fun(), options);
};
export const useGetTransactionHistoryDetailsCard = (
  card: string,
  queryParams: GetTransactionHistoryDetailsCardQueryParams,
  options?: SwaggerTypescriptUseQueryOptions<
    TransactionHistoryItemDetailsDto[]
  >,
  configOverride?: AxiosRequestConfig
) => {
  const { key, fun } = useGetTransactionHistoryDetailsCard.info(
    card,

    queryParams,
    configOverride
  );
  return useQuery(key, fun, options);
};
useGetTransactionHistoryDetailsCard.info = (
  card: string,
  queryParams: GetTransactionHistoryDetailsCardQueryParams,
  configOverride?: AxiosRequestConfig
) => {
  return {
    key: [getTransactionHistoryDetailsCard.key, card, queryParams] as QueryKey,
    fun: () =>
      getTransactionHistoryDetailsCard(
        card,

        queryParams,

        configOverride
      ),
  };
};
useGetTransactionHistoryDetailsCard.prefetch = (
  client: QueryClient,
  card: string,
  queryParams: GetTransactionHistoryDetailsCardQueryParams,
  options?: SwaggerTypescriptUseQueryOptions<
    TransactionHistoryItemDetailsDto[]
  >,
  configOverride?: AxiosRequestConfig
) => {
  const { key, fun } = useGetTransactionHistoryDetailsCard.info(
    card,

    queryParams,
    configOverride
  );

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery(key, () => fun(), options);
};
export const useGetTransactionHistoryDetailsV2 = (
  queryParams: GetTransactionHistoryDetailsV2QueryParams,
  options?: SwaggerTypescriptUseQueryOptions<
    TransactionHistoryItemDetailsV2Dto[]
  >,
  configOverride?: AxiosRequestConfig
) => {
  const { key, fun } = useGetTransactionHistoryDetailsV2.info(
    queryParams,
    configOverride
  );
  return useQuery(key, fun, options);
};
useGetTransactionHistoryDetailsV2.info = (
  queryParams: GetTransactionHistoryDetailsV2QueryParams,
  configOverride?: AxiosRequestConfig
) => {
  return {
    key: [getTransactionHistoryDetailsV2.key, queryParams] as QueryKey,
    fun: () =>
      getTransactionHistoryDetailsV2(
        queryParams,

        configOverride
      ),
  };
};
useGetTransactionHistoryDetailsV2.prefetch = (
  client: QueryClient,
  queryParams: GetTransactionHistoryDetailsV2QueryParams,
  options?: SwaggerTypescriptUseQueryOptions<
    TransactionHistoryItemDetailsV2Dto[]
  >,
  configOverride?: AxiosRequestConfig
) => {
  const { key, fun } = useGetTransactionHistoryDetailsV2.info(
    queryParams,
    configOverride
  );

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery(key, () => fun(), options);
};
export const useGetTransactionHistoryDetailsV2Card = (
  card: string,
  queryParams: GetTransactionHistoryDetailsV2CardQueryParams,
  options?: SwaggerTypescriptUseQueryOptions<
    TransactionHistoryItemDetailsV2Dto[]
  >,
  configOverride?: AxiosRequestConfig
) => {
  const { key, fun } = useGetTransactionHistoryDetailsV2Card.info(
    card,

    queryParams,
    configOverride
  );
  return useQuery(key, fun, options);
};
useGetTransactionHistoryDetailsV2Card.info = (
  card: string,
  queryParams: GetTransactionHistoryDetailsV2CardQueryParams,
  configOverride?: AxiosRequestConfig
) => {
  return {
    key: [
      getTransactionHistoryDetailsV2Card.key,
      card,

      queryParams,
    ] as QueryKey,
    fun: () =>
      getTransactionHistoryDetailsV2Card(
        card,

        queryParams,

        configOverride
      ),
  };
};
useGetTransactionHistoryDetailsV2Card.prefetch = (
  client: QueryClient,
  card: string,
  queryParams: GetTransactionHistoryDetailsV2CardQueryParams,
  options?: SwaggerTypescriptUseQueryOptions<
    TransactionHistoryItemDetailsV2Dto[]
  >,
  configOverride?: AxiosRequestConfig
) => {
  const { key, fun } = useGetTransactionHistoryDetailsV2Card.info(
    card,

    queryParams,
    configOverride
  );

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery(key, () => fun(), options);
};
export const useGetTransactionHistoryV2 = (
  queryParams?: GetTransactionHistoryV2QueryParams,
  options?: SwaggerTypescriptUseQueryOptions<TransactionHistoryPageDto>,
  configOverride?: AxiosRequestConfig
) => {
  const { key, fun } = useGetTransactionHistoryV2.info(
    queryParams,
    configOverride
  );
  return useQuery(key, fun, options);
};
useGetTransactionHistoryV2.info = (
  queryParams?: GetTransactionHistoryV2QueryParams,
  configOverride?: AxiosRequestConfig
) => {
  return {
    key: [getTransactionHistoryV2.key, queryParams] as QueryKey,
    fun: () =>
      getTransactionHistoryV2(
        queryParams,

        configOverride
      ),
  };
};
useGetTransactionHistoryV2.prefetch = (
  client: QueryClient,
  queryParams?: GetTransactionHistoryV2QueryParams,
  options?: SwaggerTypescriptUseQueryOptions<TransactionHistoryPageDto>,
  configOverride?: AxiosRequestConfig
) => {
  const { key, fun } = useGetTransactionHistoryV2.info(
    queryParams,
    configOverride
  );

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery(key, () => fun(), options);
};
export const useGetTransactionHistoryV2Card = (
  card: string,
  queryParams?: GetTransactionHistoryV2CardQueryParams,
  options?: SwaggerTypescriptUseQueryOptions<TransactionHistoryPageDto>,
  configOverride?: AxiosRequestConfig
) => {
  const { key, fun } = useGetTransactionHistoryV2Card.info(
    card,

    queryParams,
    configOverride
  );
  return useQuery(key, fun, options);
};
useGetTransactionHistoryV2Card.info = (
  card: string,
  queryParams?: GetTransactionHistoryV2CardQueryParams,
  configOverride?: AxiosRequestConfig
) => {
  return {
    key: [getTransactionHistoryV2Card.key, card, queryParams] as QueryKey,
    fun: () =>
      getTransactionHistoryV2Card(
        card,

        queryParams,

        configOverride
      ),
  };
};
useGetTransactionHistoryV2Card.prefetch = (
  client: QueryClient,
  card: string,
  queryParams?: GetTransactionHistoryV2CardQueryParams,
  options?: SwaggerTypescriptUseQueryOptions<TransactionHistoryPageDto>,
  configOverride?: AxiosRequestConfig
) => {
  const { key, fun } = useGetTransactionHistoryV2Card.info(
    card,

    queryParams,
    configOverride
  );

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery(key, () => fun(), options);
};
export const useGetTransactions = (
  options?: SwaggerTypescriptUseQueryOptions<TransactionDto[]>,
  configOverride?: AxiosRequestConfig
) => {
  const { key, fun } = useGetTransactions.info(configOverride);
  return useQuery(key, fun, options);
};
useGetTransactions.info = (configOverride?: AxiosRequestConfig) => {
  return {
    key: [getTransactions.key] as QueryKey,
    fun: () => getTransactions(configOverride),
  };
};
useGetTransactions.prefetch = (
  client: QueryClient,
  options?: SwaggerTypescriptUseQueryOptions<TransactionDto[]>,
  configOverride?: AxiosRequestConfig
) => {
  const { key, fun } = useGetTransactions.info(configOverride);

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery(key, () => fun(), options);
};
export const useGetTransactionsCommented = (
  options?: SwaggerTypescriptUseQueryOptions<ProductWithTransactionDto[]>,
  configOverride?: AxiosRequestConfig
) => {
  const { key, fun } = useGetTransactionsCommented.info(configOverride);
  return useQuery(key, fun, options);
};
useGetTransactionsCommented.info = (configOverride?: AxiosRequestConfig) => {
  return {
    key: [getTransactionsCommented.key] as QueryKey,
    fun: () => getTransactionsCommented(configOverride),
  };
};
useGetTransactionsCommented.prefetch = (
  client: QueryClient,
  options?: SwaggerTypescriptUseQueryOptions<ProductWithTransactionDto[]>,
  configOverride?: AxiosRequestConfig
) => {
  const { key, fun } = useGetTransactionsCommented.info(configOverride);

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery(key, () => fun(), options);
};
export const useGetTransactionsLiked = (
  options?: SwaggerTypescriptUseQueryOptions<ProductWithTransactionDto[]>,
  configOverride?: AxiosRequestConfig
) => {
  const { key, fun } = useGetTransactionsLiked.info(configOverride);
  return useQuery(key, fun, options);
};
useGetTransactionsLiked.info = (configOverride?: AxiosRequestConfig) => {
  return {
    key: [getTransactionsLiked.key] as QueryKey,
    fun: () => getTransactionsLiked(configOverride),
  };
};
useGetTransactionsLiked.prefetch = (
  client: QueryClient,
  options?: SwaggerTypescriptUseQueryOptions<ProductWithTransactionDto[]>,
  configOverride?: AxiosRequestConfig
) => {
  const { key, fun } = useGetTransactionsLiked.info(configOverride);

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery(key, () => fun(), options);
};
export const useGetTransactionsPurchased = (
  options?: SwaggerTypescriptUseQueryOptions<ProductWithTransactionDto[]>,
  configOverride?: AxiosRequestConfig
) => {
  const { key, fun } = useGetTransactionsPurchased.info(configOverride);
  return useQuery(key, fun, options);
};
useGetTransactionsPurchased.info = (configOverride?: AxiosRequestConfig) => {
  return {
    key: [getTransactionsPurchased.key] as QueryKey,
    fun: () => getTransactionsPurchased(configOverride),
  };
};
useGetTransactionsPurchased.prefetch = (
  client: QueryClient,
  options?: SwaggerTypescriptUseQueryOptions<ProductWithTransactionDto[]>,
  configOverride?: AxiosRequestConfig
) => {
  const { key, fun } = useGetTransactionsPurchased.info(configOverride);

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery(key, () => fun(), options);
};
export const useGetTransactionsRated = (
  options?: SwaggerTypescriptUseQueryOptions<ProductWithTransactionDto[]>,
  configOverride?: AxiosRequestConfig
) => {
  const { key, fun } = useGetTransactionsRated.info(configOverride);
  return useQuery(key, fun, options);
};
useGetTransactionsRated.info = (configOverride?: AxiosRequestConfig) => {
  return {
    key: [getTransactionsRated.key] as QueryKey,
    fun: () => getTransactionsRated(configOverride),
  };
};
useGetTransactionsRated.prefetch = (
  client: QueryClient,
  options?: SwaggerTypescriptUseQueryOptions<ProductWithTransactionDto[]>,
  configOverride?: AxiosRequestConfig
) => {
  const { key, fun } = useGetTransactionsRated.info(configOverride);

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery(key, () => fun(), options);
};
export const useGetTransactionsRecent = (
  options?: SwaggerTypescriptUseQueryOptions<ProductWithTransactionDto[]>,
  configOverride?: AxiosRequestConfig
) => {
  const { key, fun } = useGetTransactionsRecent.info(configOverride);
  return useQuery(key, fun, options);
};
useGetTransactionsRecent.info = (configOverride?: AxiosRequestConfig) => {
  return {
    key: [getTransactionsRecent.key] as QueryKey,
    fun: () => getTransactionsRecent(configOverride),
  };
};
useGetTransactionsRecent.prefetch = (
  client: QueryClient,
  options?: SwaggerTypescriptUseQueryOptions<ProductWithTransactionDto[]>,
  configOverride?: AxiosRequestConfig
) => {
  const { key, fun } = useGetTransactionsRecent.info(configOverride);

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery(key, () => fun(), options);
};
export const useGetTransactionsRecommended = (
  options?: SwaggerTypescriptUseQueryOptions<ProductWithTransactionDto[]>,
  configOverride?: AxiosRequestConfig
) => {
  const { key, fun } = useGetTransactionsRecommended.info(configOverride);
  return useQuery(key, fun, options);
};
useGetTransactionsRecommended.info = (configOverride?: AxiosRequestConfig) => {
  return {
    key: [getTransactionsRecommended.key] as QueryKey,
    fun: () => getTransactionsRecommended(configOverride),
  };
};
useGetTransactionsRecommended.prefetch = (
  client: QueryClient,
  options?: SwaggerTypescriptUseQueryOptions<ProductWithTransactionDto[]>,
  configOverride?: AxiosRequestConfig
) => {
  const { key, fun } = useGetTransactionsRecommended.info(configOverride);

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery(key, () => fun(), options);
};
export const useGetTransactionsV2 = (
  options?: SwaggerTypescriptUseQueryOptions<ProductWithTransactionV2Dto[]>,
  configOverride?: AxiosRequestConfig
) => {
  const { key, fun } = useGetTransactionsV2.info(configOverride);
  return useQuery(key, fun, options);
};
useGetTransactionsV2.info = (configOverride?: AxiosRequestConfig) => {
  return {
    key: [getTransactionsV2.key] as QueryKey,
    fun: () => getTransactionsV2(configOverride),
  };
};
useGetTransactionsV2.prefetch = (
  client: QueryClient,
  options?: SwaggerTypescriptUseQueryOptions<ProductWithTransactionV2Dto[]>,
  configOverride?: AxiosRequestConfig
) => {
  const { key, fun } = useGetTransactionsV2.info(configOverride);

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery(key, () => fun(), options);
};
export const useGetTransactionsV2Commented = (
  options?: SwaggerTypescriptUseQueryOptions<ProductWithTransactionV2Dto[]>,
  configOverride?: AxiosRequestConfig
) => {
  const { key, fun } = useGetTransactionsV2Commented.info(configOverride);
  return useQuery(key, fun, options);
};
useGetTransactionsV2Commented.info = (configOverride?: AxiosRequestConfig) => {
  return {
    key: [getTransactionsV2Commented.key] as QueryKey,
    fun: () => getTransactionsV2Commented(configOverride),
  };
};
useGetTransactionsV2Commented.prefetch = (
  client: QueryClient,
  options?: SwaggerTypescriptUseQueryOptions<ProductWithTransactionV2Dto[]>,
  configOverride?: AxiosRequestConfig
) => {
  const { key, fun } = useGetTransactionsV2Commented.info(configOverride);

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery(key, () => fun(), options);
};
export const useGetTransactionsV2Liked = (
  options?: SwaggerTypescriptUseQueryOptions<ProductWithTransactionV2Dto[]>,
  configOverride?: AxiosRequestConfig
) => {
  const { key, fun } = useGetTransactionsV2Liked.info(configOverride);
  return useQuery(key, fun, options);
};
useGetTransactionsV2Liked.info = (configOverride?: AxiosRequestConfig) => {
  return {
    key: [getTransactionsV2Liked.key] as QueryKey,
    fun: () => getTransactionsV2Liked(configOverride),
  };
};
useGetTransactionsV2Liked.prefetch = (
  client: QueryClient,
  options?: SwaggerTypescriptUseQueryOptions<ProductWithTransactionV2Dto[]>,
  configOverride?: AxiosRequestConfig
) => {
  const { key, fun } = useGetTransactionsV2Liked.info(configOverride);

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery(key, () => fun(), options);
};
export const useGetTransactionsV2Purchased = (
  options?: SwaggerTypescriptUseQueryOptions<ProductWithTransactionV2Dto[]>,
  configOverride?: AxiosRequestConfig
) => {
  const { key, fun } = useGetTransactionsV2Purchased.info(configOverride);
  return useQuery(key, fun, options);
};
useGetTransactionsV2Purchased.info = (configOverride?: AxiosRequestConfig) => {
  return {
    key: [getTransactionsV2Purchased.key] as QueryKey,
    fun: () => getTransactionsV2Purchased(configOverride),
  };
};
useGetTransactionsV2Purchased.prefetch = (
  client: QueryClient,
  options?: SwaggerTypescriptUseQueryOptions<ProductWithTransactionV2Dto[]>,
  configOverride?: AxiosRequestConfig
) => {
  const { key, fun } = useGetTransactionsV2Purchased.info(configOverride);

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery(key, () => fun(), options);
};
export const useGetTransactionsV2Rated = (
  options?: SwaggerTypescriptUseQueryOptions<ProductWithTransactionV2Dto[]>,
  configOverride?: AxiosRequestConfig
) => {
  const { key, fun } = useGetTransactionsV2Rated.info(configOverride);
  return useQuery(key, fun, options);
};
useGetTransactionsV2Rated.info = (configOverride?: AxiosRequestConfig) => {
  return {
    key: [getTransactionsV2Rated.key] as QueryKey,
    fun: () => getTransactionsV2Rated(configOverride),
  };
};
useGetTransactionsV2Rated.prefetch = (
  client: QueryClient,
  options?: SwaggerTypescriptUseQueryOptions<ProductWithTransactionV2Dto[]>,
  configOverride?: AxiosRequestConfig
) => {
  const { key, fun } = useGetTransactionsV2Rated.info(configOverride);

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery(key, () => fun(), options);
};
export const useGetTransactionsV2Recent = (
  options?: SwaggerTypescriptUseQueryOptions<ProductWithTransactionV2Dto[]>,
  configOverride?: AxiosRequestConfig
) => {
  const { key, fun } = useGetTransactionsV2Recent.info(configOverride);
  return useQuery(key, fun, options);
};
useGetTransactionsV2Recent.info = (configOverride?: AxiosRequestConfig) => {
  return {
    key: [getTransactionsV2Recent.key] as QueryKey,
    fun: () => getTransactionsV2Recent(configOverride),
  };
};
useGetTransactionsV2Recent.prefetch = (
  client: QueryClient,
  options?: SwaggerTypescriptUseQueryOptions<ProductWithTransactionV2Dto[]>,
  configOverride?: AxiosRequestConfig
) => {
  const { key, fun } = useGetTransactionsV2Recent.info(configOverride);

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery(key, () => fun(), options);
};
export const useGetTransactionsV2Recommended = (
  options?: SwaggerTypescriptUseQueryOptions<ProductWithTransactionV2Dto[]>,
  configOverride?: AxiosRequestConfig
) => {
  const { key, fun } = useGetTransactionsV2Recommended.info(configOverride);
  return useQuery(key, fun, options);
};
useGetTransactionsV2Recommended.info = (
  configOverride?: AxiosRequestConfig
) => {
  return {
    key: [getTransactionsV2Recommended.key] as QueryKey,
    fun: () => getTransactionsV2Recommended(configOverride),
  };
};
useGetTransactionsV2Recommended.prefetch = (
  client: QueryClient,
  options?: SwaggerTypescriptUseQueryOptions<ProductWithTransactionV2Dto[]>,
  configOverride?: AxiosRequestConfig
) => {
  const { key, fun } = useGetTransactionsV2Recommended.info(configOverride);

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery(key, () => fun(), options);
};
export const useGetUserDetails = (
  options?: SwaggerTypescriptUseQueryOptions<UserDetailsDto>,
  configOverride?: AxiosRequestConfig
) => {
  const { key, fun } = useGetUserDetails.info(configOverride);
  return useQuery(key, fun, options);
};
useGetUserDetails.info = (configOverride?: AxiosRequestConfig) => {
  return {
    key: [getUserDetails.key] as QueryKey,
    fun: () => getUserDetails(configOverride),
  };
};
useGetUserDetails.prefetch = (
  client: QueryClient,
  options?: SwaggerTypescriptUseQueryOptions<UserDetailsDto>,
  configOverride?: AxiosRequestConfig
) => {
  const { key, fun } = useGetUserDetails.info(configOverride);

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery(key, () => fun(), options);
};
export const useGetUserLikeId = (
  id: string,
  options?: SwaggerTypescriptUseQueryOptions<MessageDto>,
  configOverride?: AxiosRequestConfig
) => {
  const { key, fun } = useGetUserLikeId.info(
    id,

    configOverride
  );
  return useQuery(key, fun, options);
};
useGetUserLikeId.info = (id: string, configOverride?: AxiosRequestConfig) => {
  return {
    key: [getUserLikeId.key, id] as QueryKey,
    fun: () =>
      getUserLikeId(
        id,

        configOverride
      ),
  };
};
useGetUserLikeId.prefetch = (
  client: QueryClient,
  id: string,
  options?: SwaggerTypescriptUseQueryOptions<MessageDto>,
  configOverride?: AxiosRequestConfig
) => {
  const { key, fun } = useGetUserLikeId.info(
    id,

    configOverride
  );

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery(key, () => fun(), options);
};
export const useGetUserProducts = (
  options?: SwaggerTypescriptUseQueryOptions<ProductWithTransactionDto[]>,
  configOverride?: AxiosRequestConfig
) => {
  const { key, fun } = useGetUserProducts.info(configOverride);
  return useQuery(key, fun, options);
};
useGetUserProducts.info = (configOverride?: AxiosRequestConfig) => {
  return {
    key: [getUserProducts.key] as QueryKey,
    fun: () => getUserProducts(configOverride),
  };
};
useGetUserProducts.prefetch = (
  client: QueryClient,
  options?: SwaggerTypescriptUseQueryOptions<ProductWithTransactionDto[]>,
  configOverride?: AxiosRequestConfig
) => {
  const { key, fun } = useGetUserProducts.info(configOverride);

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery(key, () => fun(), options);
};
export const useGetUserProductsId = (
  /**
   *
   * Pim ID
   */
  id: string,
  options?: SwaggerTypescriptUseQueryOptions<ProductWithTransactionDto>,
  configOverride?: AxiosRequestConfig
) => {
  const { key, fun } = useGetUserProductsId.info(
    id,

    configOverride
  );
  return useQuery(key, fun, options);
};
useGetUserProductsId.info = (
  /**
   *
   * Pim ID
   */
  id: string,
  configOverride?: AxiosRequestConfig
) => {
  return {
    key: [getUserProductsId.key, id] as QueryKey,
    fun: () =>
      getUserProductsId(
        id,

        configOverride
      ),
  };
};
useGetUserProductsId.prefetch = (
  client: QueryClient,

  /**
   *
   * Pim ID
   */
  id: string,
  options?: SwaggerTypescriptUseQueryOptions<ProductWithTransactionDto>,
  configOverride?: AxiosRequestConfig
) => {
  const { key, fun } = useGetUserProductsId.info(
    id,

    configOverride
  );

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery(key, () => fun(), options);
};
export const useGetUserUnlikeId = (
  id: string,
  options?: SwaggerTypescriptUseQueryOptions<MessageDto>,
  configOverride?: AxiosRequestConfig
) => {
  const { key, fun } = useGetUserUnlikeId.info(
    id,

    configOverride
  );
  return useQuery(key, fun, options);
};
useGetUserUnlikeId.info = (id: string, configOverride?: AxiosRequestConfig) => {
  return {
    key: [getUserUnlikeId.key, id] as QueryKey,
    fun: () =>
      getUserUnlikeId(
        id,

        configOverride
      ),
  };
};
useGetUserUnlikeId.prefetch = (
  client: QueryClient,
  id: string,
  options?: SwaggerTypescriptUseQueryOptions<MessageDto>,
  configOverride?: AxiosRequestConfig
) => {
  const { key, fun } = useGetUserUnlikeId.info(
    id,

    configOverride
  );

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery(key, () => fun(), options);
};
export const useGetUserV2Products = (
  options?: SwaggerTypescriptUseQueryOptions<ProductWithTransactionV2Dto[]>,
  configOverride?: AxiosRequestConfig
) => {
  const { key, fun } = useGetUserV2Products.info(configOverride);
  return useQuery(key, fun, options);
};
useGetUserV2Products.info = (configOverride?: AxiosRequestConfig) => {
  return {
    key: [getUserV2Products.key] as QueryKey,
    fun: () => getUserV2Products(configOverride),
  };
};
useGetUserV2Products.prefetch = (
  client: QueryClient,
  options?: SwaggerTypescriptUseQueryOptions<ProductWithTransactionV2Dto[]>,
  configOverride?: AxiosRequestConfig
) => {
  const { key, fun } = useGetUserV2Products.info(configOverride);

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery(key, () => fun(), options);
};
export const usePostAccountAppOpened = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    LoginResponseDto,
    { headerParams?: { platform: string; version: string } },
    TExtra
  >
) => {
  return useMutation((_o) => {
    const { headerParams, configOverride } = _o || {};

    return postAccountAppOpened(headerParams, configOverride);
  }, options);
};

export const usePostAccountAuthcodeConfirmEmail = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    UserDto,
    { requestBody: EmailAuthCodeConfirmBodyDto },
    TExtra
  >
) => {
  return useMutation((_o) => {
    const {
      requestBody,

      configOverride,
    } = _o || {};

    return postAccountAuthcodeConfirmEmail(
      requestBody,

      configOverride
    );
  }, options);
};

export const usePostAccountAuthcodeEmail = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptionsVoid<
    AuthCodeSendResponseDto,
    TExtra
  >
) => {
  return useMutation((_o) => {
    const { configOverride } = _o || {};

    return postAccountAuthcodeEmail(configOverride);
  }, options);
};

export const usePostAccountConfirm = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    SignupConfirmResponseDto,
    { requestBody: ConfirmSignupDto },
    TExtra
  >
) => {
  return useMutation((_o) => {
    const {
      requestBody,

      configOverride,
    } = _o || {};

    return postAccountConfirm(
      requestBody,

      configOverride
    );
  }, options);
};

export const usePostAccountConfirmonboard = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    SignupConfirmResponseDto,
    { requestBody: ConfirmSignupDto },
    TExtra
  >
) => {
  return useMutation((_o) => {
    const {
      requestBody,

      configOverride,
    } = _o || {};

    return postAccountConfirmonboard(
      requestBody,

      configOverride
    );
  }, options);
};

export const usePostAccountEmailConfirm = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    UserDto,
    { requestBody: EmailChangeConfirmBodyDto },
    TExtra
  >
) => {
  return useMutation((_o) => {
    const {
      requestBody,

      configOverride,
    } = _o || {};

    return postAccountEmailConfirm(
      requestBody,

      configOverride
    );
  }, options);
};

export const usePostAccountLogin = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    LoginResponseDto,
    { requestBody: LoginBodyDto; headerParams?: { version: string } },
    TExtra
  >
) => {
  return useMutation((_o) => {
    const {
      requestBody,

      headerParams,
      configOverride,
    } = _o || {};

    return postAccountLogin(
      requestBody,

      headerParams,
      configOverride
    );
  }, options);
};

export const usePostAccountLoginService = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    KeycloakLoginResponseDto,
    { requestBody: LoginClientCredentialsBodyDto },
    TExtra
  >
) => {
  return useMutation((_o) => {
    const {
      requestBody,

      configOverride,
    } = _o || {};

    return postAccountLoginService(
      requestBody,

      configOverride
    );
  }, options);
};

export const usePostAccountMktgcommunitation = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    MessageDto,
    { requestBody: MarketingCommunicationsBodyDto },
    TExtra
  >
) => {
  return useMutation((_o) => {
    const {
      requestBody,

      configOverride,
    } = _o || {};

    return postAccountMktgcommunitation(
      requestBody,

      configOverride
    );
  }, options);
};

export const usePostAccountOnboardauth = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    MessageDto,
    { requestBody: OnboardAuthDto },
    TExtra
  >
) => {
  return useMutation((_o) => {
    const {
      requestBody,

      configOverride,
    } = _o || {};

    return postAccountOnboardauth(
      requestBody,

      configOverride
    );
  }, options);
};

export const usePostAccountPasswordchange = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    MessageDto,
    { requestBody: PasswordChangeBodyDto },
    TExtra
  >
) => {
  return useMutation((_o) => {
    const {
      requestBody,

      configOverride,
    } = _o || {};

    return postAccountPasswordchange(
      requestBody,

      configOverride
    );
  }, options);
};

export const usePostAccountPasswordremind = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    PasswordRemindResponseDto,
    { requestBody: PasswordRemindBodyDto },
    TExtra
  >
) => {
  return useMutation((_o) => {
    const {
      requestBody,

      configOverride,
    } = _o || {};

    return postAccountPasswordremind(
      requestBody,

      configOverride
    );
  }, options);
};

export const usePostAccountPhoneConfirm = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    UserDto,
    { requestBody: PhoneChangeConfirmBodyDto },
    TExtra
  >
) => {
  return useMutation((_o) => {
    const {
      requestBody,

      configOverride,
    } = _o || {};

    return postAccountPhoneConfirm(
      requestBody,

      configOverride
    );
  }, options);
};

export const usePostAccountRefresh_token = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    RefreshTokenResponseDto,
    { requestBody: RefreshTokenBodyDto },
    TExtra
  >
) => {
  return useMutation((_o) => {
    const {
      requestBody,

      configOverride,
    } = _o || {};

    return postAccountRefresh_token(
      requestBody,

      configOverride
    );
  }, options);
};

export const usePostAccountVersioncheck = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    { requestBody: CheckVersionBodyDto },
    TExtra
  >
) => {
  return useMutation((_o) => {
    const {
      requestBody,

      configOverride,
    } = _o || {};

    return postAccountVersioncheck(
      requestBody,

      configOverride
    );
  }, options);
};

export const usePostApkDevice = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    MessageDto,
    { requestBody: DeviceSetupBodyDto },
    TExtra
  >
) => {
  return useMutation((_o) => {
    const {
      requestBody,

      configOverride,
    } = _o || {};

    return postApkDevice(
      requestBody,

      configOverride
    );
  }, options);
};

export const usePostApkSubmiterror = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    ApkBugDto,
    {
      requestBody: SubmitApkErrorDto;
      headerParams?: { platform: string; version: string };
    },
    TExtra
  >
) => {
  return useMutation((_o) => {
    const {
      requestBody,

      headerParams,
      configOverride,
    } = _o || {};

    return postApkSubmiterror(
      requestBody,

      headerParams,
      configOverride
    );
  }, options);
};

export const usePostApkVersioncheck = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    VersionCheckDto,
    { requestBody: CheckVersionBodyDto },
    TExtra
  >
) => {
  return useMutation((_o) => {
    const {
      requestBody,

      configOverride,
    } = _o || {};

    return postApkVersioncheck(
      requestBody,

      configOverride
    );
  }, options);
};

export const usePostConsents = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    MessageDto,
    { requestBody: SaveConsentsBodyDto },
    TExtra
  >
) => {
  return useMutation((_o) => {
    const {
      requestBody,

      configOverride,
    } = _o || {};

    return postConsents(
      requestBody,

      configOverride
    );
  }, options);
};

export const usePostExternalLink = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptionsVoid<UserTokenDto, TExtra>
) => {
  return useMutation((_o) => {
    const { configOverride } = _o || {};

    return postExternalLink(configOverride);
  }, options);
};

export const usePostExternalLinkValidate = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    ValidateTokenUserDto,
    { requestBody: ValidateTokenDto },
    TExtra
  >
) => {
  return useMutation((_o) => {
    const {
      requestBody,

      configOverride,
    } = _o || {};

    return postExternalLinkValidate(
      requestBody,

      configOverride
    );
  }, options);
};

export const usePostNotDelnotification = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    NotificationDto,
    { requestBody: DeleteNotificationBodyDto },
    TExtra
  >
) => {
  return useMutation((_o) => {
    const {
      requestBody,

      configOverride,
    } = _o || {};

    return postNotDelnotification(
      requestBody,

      configOverride
    );
  }, options);
};

export const usePostNotNotifications = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    NotificationDto,
    { requestBody: CreateNotificationBodyDto },
    TExtra
  >
) => {
  return useMutation((_o) => {
    const {
      requestBody,

      configOverride,
    } = _o || {};

    return postNotNotifications(
      requestBody,

      configOverride
    );
  }, options);
};

export const usePostNotNotificationsAddnotification = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    NotificationDto,
    { requestBody: CreateNotificationBodyDto },
    TExtra
  >
) => {
  return useMutation((_o) => {
    const {
      requestBody,

      configOverride,
    } = _o || {};

    return postNotNotificationsAddnotification(
      requestBody,

      configOverride
    );
  }, options);
};

export const usePostOnboardingAuthcodeConfirmEmail = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    EmailConfirmResponseDto,
    { requestBody: OnboardingEmailAuthCodeConfirmBodyDto },
    TExtra
  >
) => {
  return useMutation((_o) => {
    const {
      requestBody,

      configOverride,
    } = _o || {};

    return postOnboardingAuthcodeConfirmEmail(
      requestBody,

      configOverride
    );
  }, options);
};

export const usePostOnboardingAuthcodeConfirmSms = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    PhoneConfirmResponseDto,
    { requestBody: SmsAuthCodeConfirmBodyDto },
    TExtra
  >
) => {
  return useMutation((_o) => {
    const {
      requestBody,

      configOverride,
    } = _o || {};

    return postOnboardingAuthcodeConfirmSms(
      requestBody,

      configOverride
    );
  }, options);
};

export const usePostOnboardingAuthcodeEmail = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    AuthCodeSendResponseDto,
    { requestBody: OnboardingEmailAuthCodeSendBodyDto },
    TExtra
  >
) => {
  return useMutation((_o) => {
    const {
      requestBody,

      configOverride,
    } = _o || {};

    return postOnboardingAuthcodeEmail(
      requestBody,

      configOverride
    );
  }, options);
};

export const usePostOnboardingAuthcodeRetriverSms = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    AuthCodeSendResponseDto,
    { requestBody: SmsRetriverAuthCodeBodyDto },
    TExtra
  >
) => {
  return useMutation((_o) => {
    const {
      requestBody,

      configOverride,
    } = _o || {};

    return postOnboardingAuthcodeRetriverSms(
      requestBody,

      configOverride
    );
  }, options);
};

export const usePostOnboardingAuthcodeSms = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    AuthCodeSendResponseDto,
    { requestBody: SmsAuthCodeBodyDto },
    TExtra
  >
) => {
  return useMutation((_o) => {
    const {
      requestBody,

      configOverride,
    } = _o || {};

    return postOnboardingAuthcodeSms(
      requestBody,

      configOverride
    );
  }, options);
};

export const usePostOnboardingSignup = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    LoginResponseDto,
    { requestBody: OnboardingSignupBodyDto },
    TExtra
  >
) => {
  return useMutation((_o) => {
    const {
      requestBody,

      configOverride,
    } = _o || {};

    return postOnboardingSignup(
      requestBody,

      configOverride
    );
  }, options);
};

export const usePostOnboardingValidateEmail = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    OnboardingDto,
    { requestBody: OnboardingEmailValidateBodyDto },
    TExtra
  >
) => {
  return useMutation((_o) => {
    const {
      requestBody,

      configOverride,
    } = _o || {};

    return postOnboardingValidateEmail(
      requestBody,

      configOverride
    );
  }, options);
};

export const usePostPoliciesUpdate = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptionsVoid<MessageDto, TExtra>
) => {
  return useMutation((_o) => {
    const { configOverride } = _o || {};

    return postPoliciesUpdate(configOverride);
  }, options);
};

/**
 * @deprecated This endpoint deprecated and will be remove. Please use an alternative
 */
export const usePostProductSearch = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    ProductSearchResultDto,
    { requestBody: ProductSearchBodyDto },
    TExtra
  >
) => {
  return useMutation((_o) => {
    const {
      requestBody,

      configOverride,
    } = _o || {};

    return postProductSearch(
      requestBody,

      configOverride
    );
  }, options);
};

export const usePostProductSearchAlcohol = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    ProductSearchResultDto,
    { requestBody: ProductSearchBodyDto },
    TExtra
  >
) => {
  return useMutation((_o) => {
    const {
      requestBody,

      configOverride,
    } = _o || {};

    return postProductSearchAlcohol(
      requestBody,

      configOverride
    );
  }, options);
};

export const usePostProductSearchAlcoholCard = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    AlcoholSearchResultDto,
    { card: string; requestBody: ProductSearchBodyDto },
    TExtra
  >
) => {
  return useMutation((_o) => {
    const {
      card,
      requestBody,

      configOverride,
    } = _o || {};

    return postProductSearchAlcoholCard(
      card,
      requestBody,

      configOverride
    );
  }, options);
};

export const usePostProductSearchCard = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    ProductSearchResultDto,
    { card: string; requestBody: ProductSearchBodyDto },
    TExtra
  >
) => {
  return useMutation((_o) => {
    const {
      card,
      requestBody,

      configOverride,
    } = _o || {};

    return postProductSearchCard(
      card,
      requestBody,

      configOverride
    );
  }, options);
};

export const usePostProductSearchWine = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    ProductSearchResultDto,
    { requestBody: ProductSearchBodyDto },
    TExtra
  >
) => {
  return useMutation((_o) => {
    const {
      requestBody,

      configOverride,
    } = _o || {};

    return postProductSearchWine(
      requestBody,

      configOverride
    );
  }, options);
};

export const usePostProductSearchWineCard = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    WineSearchResultDto,
    { card: string; requestBody: ProductSearchBodyDto },
    TExtra
  >
) => {
  return useMutation((_o) => {
    const {
      card,
      requestBody,

      configOverride,
    } = _o || {};

    return postProductSearchWineCard(
      card,
      requestBody,

      configOverride
    );
  }, options);
};

export const usePostReferalUse = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    { requestBody: UseReferralCodeDto },
    TExtra
  >
) => {
  return useMutation((_o) => {
    const {
      requestBody,

      configOverride,
    } = _o || {};

    return postReferalUse(
      requestBody,

      configOverride
    );
  }, options);
};

export const usePostReferalValidate = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    ReferralDto,
    { requestBody: ValidateReferralCodeDto },
    TExtra
  >
) => {
  return useMutation((_o) => {
    const {
      requestBody,

      configOverride,
    } = _o || {};

    return postReferalValidate(
      requestBody,

      configOverride
    );
  }, options);
};

export const usePostReferralUse = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    { requestBody: UseReferralCodeDto },
    TExtra
  >
) => {
  return useMutation((_o) => {
    const {
      requestBody,

      configOverride,
    } = _o || {};

    return postReferralUse(
      requestBody,

      configOverride
    );
  }, options);
};

export const usePostReferralValidate = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    ReferralDto,
    { requestBody: ValidateReferralCodeDto },
    TExtra
  >
) => {
  return useMutation((_o) => {
    const {
      requestBody,

      configOverride,
    } = _o || {};

    return postReferralValidate(
      requestBody,

      configOverride
    );
  }, options);
};

export const usePostSearchGatherimagedata = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    { requestBody: GatherImageDataBodyDto },
    TExtra
  >
) => {
  return useMutation((_o) => {
    const {
      requestBody,

      configOverride,
    } = _o || {};

    return postSearchGatherimagedata(
      requestBody,

      configOverride
    );
  }, options);
};

export const usePostSearchImage = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    { requestBody: UploadImageBodyDto },
    TExtra
  >
) => {
  return useMutation((_o) => {
    const {
      requestBody,

      configOverride,
    } = _o || {};

    return postSearchImage(
      requestBody,

      configOverride
    );
  }, options);
};

export const usePostUserApprated = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptionsVoid<any, TExtra>
) => {
  return useMutation((_o) => {
    const { configOverride } = _o || {};

    return postUserApprated(configOverride);
  }, options);
};

export const usePostUserComment = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    MessageDto,
    { requestBody: CommentWineBodyDto },
    TExtra
  >
) => {
  return useMutation((_o) => {
    const {
      requestBody,

      configOverride,
    } = _o || {};

    return postUserComment(
      requestBody,

      configOverride
    );
  }, options);
};

export const usePostUserPreferences = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    MessageDto,
    { requestBody: UpdatePreferencesBodyDto },
    TExtra
  >
) => {
  return useMutation((_o) => {
    const {
      requestBody,

      configOverride,
    } = _o || {};

    return postUserPreferences(
      requestBody,

      configOverride
    );
  }, options);
};

export const usePostUserRate = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    MessageDto,
    { requestBody: RateWineBodyDto },
    TExtra
  >
) => {
  return useMutation((_o) => {
    const {
      requestBody,

      configOverride,
    } = _o || {};

    return postUserRate(
      requestBody,

      configOverride
    );
  }, options);
};

export const usePutAccountEmail = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    AuthCodeSendResponseDto,
    { requestBody: EmailChangeBodyDto },
    TExtra
  >
) => {
  return useMutation((_o) => {
    const {
      requestBody,

      configOverride,
    } = _o || {};

    return putAccountEmail(
      requestBody,

      configOverride
    );
  }, options);
};

export const usePutAccountPhone = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    AuthCodeSendResponseDto,
    { requestBody: PhoneChangeBodyDto },
    TExtra
  >
) => {
  return useMutation((_o) => {
    const {
      requestBody,

      configOverride,
    } = _o || {};

    return putAccountPhone(
      requestBody,

      configOverride
    );
  }, options);
};

export const usePutConsents = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    MessageDto,
    { requestBody: UpdateConsentsBodyDto },
    TExtra
  >
) => {
  return useMutation((_o) => {
    const {
      requestBody,

      configOverride,
    } = _o || {};

    return putConsents(
      requestBody,

      configOverride
    );
  }, options);
};

export const usePutUserProfile = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    UserDetailsDto,
    { requestBody: UpdateUserBodyDto },
    TExtra
  >
) => {
  return useMutation((_o) => {
    const {
      requestBody,

      configOverride,
    } = _o || {};

    return putUserProfile(
      requestBody,

      configOverride
    );
  }, options);
};
