import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { Auth } from './components/auth.component';
import { createBrowserRouter, RouterProvider, Link } from 'react-router-dom';
import { Test } from './components/test.component';
import * as Sentry from '@sentry/react';


const API_URL = process.env.VITE_API_URL;
const SENTRY = process.env.VITE_SENTRY || "1";
const SENTRY_ENVIRONMENT = process.env.VITE_SENTRY_ENVIRONMENT || 'prod';
const SENTRY_SAMPLE_RATE = Number(process.env.VITE_SENTRY_SAMPLE_RATE || 1.0);
const SENTRY_TRACES_SAMPLE_RATE = Number(process.env.VITE_SENTRY_TRACES_SAMPLE_RATE || 0.1);
const SENTRY_REPLAYS_SESSION_SAMPLE_RATE = Number(process.env.VITE_SENTRY_REPLAYS_SESSION_SAMPLE_RATE || 0.0);
const SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE = Number(process.env.VITE_SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE || 1.0);
const SENTRY_REPLAYS_MASK_ALL_TEXT = JSON.parse((process.env.VITE_SENTRY_REPLAYS_MASK_ALL_TEXT || 'true').toLowerCase());
const SENTRY_REPLAYS_MASK_ALL_INPUTS = JSON.parse((process.env.VITE_SENTRY_REPLAYS_MASK_ALL_INPUTS || 'true').toLowerCase());
const SENTRY_REPLAYS_BLOCK_ALL_MEDIA = JSON.parse((process.env.VITE_SENTRY_REPLAYS_BLOCK_ALL_MEDIA || 'false').toLowerCase());


if (SENTRY === "1") {
  Sentry.init({
    dsn: "https://fbfae8961e93fa9dad34effcf9fdb1cc@o4507588270096384.ingest.de.sentry.io/4507610586677328",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: SENTRY_REPLAYS_MASK_ALL_TEXT,
        maskAllInputs: SENTRY_REPLAYS_MASK_ALL_INPUTS,
        blockAllMedia: SENTRY_REPLAYS_BLOCK_ALL_MEDIA,
      }),
    ],
    environment: SENTRY_ENVIRONMENT,
    sampleRate: SENTRY_SAMPLE_RATE, // errors
    tracesSampleRate: SENTRY_TRACES_SAMPLE_RATE, // transactions
    replaysSessionSampleRate: SENTRY_REPLAYS_SESSION_SAMPLE_RATE, // reply session
    replaysOnErrorSampleRate: SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE, // reply session on error
  });
}


function SentryTestButton() {
  const error_message = `Sentry test error.`;
  const debug_message = `
  API_URL: ${API_URL}
  VITE_API_URL: ${process.env.VITE_API_URL}
  SENTRY: ${SENTRY}
  SENTRY_ENVIRONMENT: ${SENTRY_ENVIRONMENT}
  SENTRY_SAMPLE_RATE: ${SENTRY_SAMPLE_RATE}
  SENTRY_TRACES_SAMPLE_RATE: ${SENTRY_TRACES_SAMPLE_RATE}
  SENTRY_REPLAYS_SESSION_SAMPLE_RATE: ${SENTRY_REPLAYS_SESSION_SAMPLE_RATE}
  SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE: ${SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE}
  SENTRY_REPLAYS_MASK_ALL_TEXT: ${SENTRY_REPLAYS_MASK_ALL_TEXT}
  SENTRY_REPLAYS_MASK_ALL_INPUTS: ${SENTRY_REPLAYS_MASK_ALL_INPUTS}
  SENTRY_REPLAYS_BLOCK_ALL_MEDIA: ${SENTRY_REPLAYS_BLOCK_ALL_MEDIA}
  `;
  console.log(debug_message);
  function triggerTestError() {
    alert(error_message);
    alert(debug_message);
    throw new Error(error_message);
  }

  return <button onClick={triggerTestError}>sentry test error</button>;
}


const router = createBrowserRouter([
  {
    path: '/',
    element: <Auth mode="login" />,
  },
  {
    path: '/login',
    element: <Auth mode="login" />,
  },
  {
    path: '/signup',
    element: <Auth mode="signup" />,
  },
  {
    path: '/test',
    element: <Test />,
  },
  {
    path: '/.well-known/health/sentry-test',
    element: (
      <SentryTestButton />
    ),
  },
]);


const queryClient = new QueryClient();
ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={router} />
    </QueryClientProvider>
  </React.StrictMode>,
);
