import { useEffect, useMemo, useState } from 'react';
import { EmailValidator } from './email-validator.component';
import { AuthSmsForm } from './phone-validator.component';
import { Signup } from './signup.component';
import { LoginResponseDto, OnboardingDto } from '../services-hooks/types';
import { useLocation } from 'react-use';
import { CircleNotch } from 'phosphor-react';
import qs from 'qs';
import { Header } from './header.component';
import { postExternalLink } from '../services-hooks/services';
import { redirect } from 'react-router-dom';

interface AuthProps {
  mode: 'login' | 'signup';
}
export const Auth: React.FC<AuthProps> = ({ mode }) => {
  const location = useLocation();
  const redirectUrl = useMemo(
    () => new URLSearchParams(location.search).get('redirectUrl'),
    [location],
  );
  const [isLoading, setIsLoading] = useState(false);
  const [activeOnboarding, setActiveOnboarding] =
    useState<OnboardingDto | null>(null);
  const [showSignup, setShowSignup] = useState(false);
  const onSessionStart = async (login: LoginResponseDto) => {
    let redirect: { url: string; query: qs.ParsedQs } | null = null;
    try {
      if (redirectUrl) {
        const splitRedirectUrl = redirectUrl.split('?');
        redirect =
          splitRedirectUrl.length > 1
            ? {
                url: splitRedirectUrl[0],
                query: qs.parse(splitRedirectUrl[1]),
              }
            : {
                url: splitRedirectUrl[0],
                query: {},
              };
      }

      setIsLoading(true);
      const externalTokenResponse = await postExternalLink({
        headers: { Authorization: `Bearer ${login.access_token}` },
      });
      if (window.opener) {
        window.opener.postMessage(
          { type: 'auth', token: externalTokenResponse.token, error: false },
          '*',
        );
        window.close();
      } else if (redirect) {
        window.location.href = `${redirect.url}?${qs.stringify({
          ...redirect.query,
          success: true,
          code: externalTokenResponse.token,
        })}`;
      }
    } catch (e) {
      if (window.opener) {
        window.opener.postMessage(
          { type: 'auth', token: null, error: true },
          '*',
        );
        window.close();
      } else if (redirect) {
        window.location.href = `${redirect.url}?${qs.stringify({
          ...redirect.query,
          error: true,
        })}`;
      }
    }
  };
  const invalidConfig = useMemo(() => {
    return !redirectUrl && !window.opener;
  }, [redirectUrl]);
  return (
    <div>
      <div className="lg:max-w-2xl mobile-full-height mx-auto lg:my-24 bg-primary-300 lg:rounded-[20px] lg:py-10 lg:px-12 lg:shadow-lg">
        {!invalidConfig ? (
          <>
            {isLoading ? (
              <div className="w-full flex justify-center">
                <CircleNotch className="animate-spin w-24 h-24 mt-5 text-white" />
              </div>
            ) : (
              <>
                {!activeOnboarding ? (
                  <AuthSmsForm
                    mode={mode}
                    onGoBack={() => {
                      if (window.opener) {
                        window.close();
                      } else {
                        window.history.back();
                      }
                    }}
                    onOnboardingStart={(onboarding) =>
                      setActiveOnboarding(onboarding)
                    }
                    onSessionStart={onSessionStart}
                  />
                ) : !showSignup ? (
                  <EmailValidator
                    onGoBack={() => {
                      setActiveOnboarding(null);
                    }}
                    onboarding={activeOnboarding}
                    onSessionStart={onSessionStart}
                    onGoToSignup={() => setShowSignup(true)}
                  />
                ) : (
                  <Signup
                    onboarding={activeOnboarding}
                    onSessionStart={onSessionStart}
                  />
                )}
              </>
            )}
          </>
        ) : (
          <>
            <Header
              onExit={() => {
                window.history.back();
              }}
            />
            <p className="text-center text-white mt-20 mx-8 md:mx-16 text-xl">
              Błędna konfiguracja, sprawdź parametry w adresie URL i spróbuj
              ponownie
            </p>
          </>
        )}
      </div>
    </div>
  );
};
