import { useEffect, useState } from 'react';

export const Test: React.FC = () => {
  const [token, setToken] = useState<string | null>(null);
  useEffect(() => {
    window.addEventListener('message', (event) => {
      // Check the event origin for security reasons.
      if (event.origin !== 'https://kochamwino.com') {
        return;
      }

      // Check if the event is the expected auth event.
      if (event.data && event.data.type === 'auth') {
        const token = event.data.token;
        // You can now use the token in your main window for further actions.
        console.log('Received token:', token);
        setToken(token);
      }
    });
  }, []);

  return (
    <div>
      <button
        onClick={() => {
          const authWindow = window.open(
            `${window.location.origin}/login`,
            'authWindow',
            'width=640, height=720',
          );
        }}
      >
        Login
      </button>
      Token: {token}
    </div>
  );
};
