import { Combobox, Transition } from '@headlessui/react';
import clsx from 'clsx';
import React, { Fragment, ReactElement, useRef, useState } from 'react';
import { countries } from './countries';
import ReactMaskedInput, {
  MaskedInputProps as ReactMaskedInputProps,
} from 'react-text-mask';

interface PhoneInputProps {
  error?: string | boolean | null;
  message?: string | number | null;
  icon?: ReactElement;
  center?: boolean;
  onValueChange: (dir: string, phone: string) => void;
  size?: 'big' | 'normal' | 'small';
}

export const PhoneInput: React.FC<
  React.HTMLProps<HTMLInputElement> & PhoneInputProps
> = ({
  className,
  icon,
  center,
  onValueChange: propsOnChange,
  ...inputProps
}) => {
  const [selected, setSelected] = useState(
    countries.find((c) => c.code === 'PL'),
  );
  const [query, setQuery] = useState('');
  const inputRef = useRef<HTMLInputElement>(null);
  const searchInputRef = useRef<HTMLInputElement>(null);
  const filteredCountries =
    query === ''
      ? countries
      : countries.filter((country) =>
          country.dial_code.toLowerCase().includes(query.toLowerCase()),
        );
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    propsOnChange(selected?.dial_code || '', value);
  };
  return (
    <>
      <div className={clsx('input-wrapper mx-auto max-w-sm', className)}>
        {inputProps.label && (
          <label
            className={clsx(
              'input-label inline-block mb-2 font-bold text-gray-900',
              {
                error: !!inputProps.error,
              },
            )}
          >
            {inputProps.label}
          </label>
        )}
        <div className="flex bg-white relative w-full rounded-md shadow-lg text-red sm:text-xl font-bold items-center">
          <Combobox
            value={selected}
            onChange={(v) => {
              setSelected(v);
              inputRef.current?.focus();
            }}
          >
            <div className="relative">
              <div className="relative w-16 cursor-default overflow-hidden rounded-lg bg-white text-left focus:outline-none sm:text-sm">
                <Combobox.Input
                  type="tel"
                  ref={searchInputRef}
                  className="w-full px-0 py-3 text-center rounded-md text-red sm:text-xl font-bold placeholder:opacity-40 placeholder:text-primary-600 border-none focus:outline-none focus:ring-0 "
                  displayValue={(country: any) => country.dial_code}
                  onChange={(event) => setQuery(event.target.value)}
                  onKeyUp={(e: any) => {
                    if (
                      e.key === 'ArrowRight' &&
                      searchInputRef.current?.selectionStart === 0
                    ) {
                      inputRef.current?.focus();
                    }
                  }}
                />
              </div>
              <Transition
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
                afterLeave={() => setQuery('')}
              >
                <Combobox.Options className="absolute mt-1 max-h-60 w-48 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm z-10">
                  {filteredCountries.length === 0 && query !== '' ? (
                    <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                      Brak wyników
                    </div>
                  ) : (
                    filteredCountries.map((country, index) => (
                      <Combobox.Option
                        key={`${index}-${country.dial_code}`}
                        className={({ active }) =>
                          `relative cursor-default select-none px-2 py-2 ${
                            active
                              ? 'bg-primary-600 text-white'
                              : 'text-gray-900'
                          }`
                        }
                        value={country}
                      >
                        {({ selected, active }) => (
                          <>
                            <span
                              className={`block truncate ${
                                selected ? 'font-medium' : 'font-normal'
                              }`}
                            >
                              {country.emoji} {country.name}
                            </span>
                          </>
                        )}
                      </Combobox.Option>
                    ))
                  )}
                </Combobox.Options>
              </Transition>
            </div>
          </Combobox>
          <div className="border-r border-red h-6" />
          <input
            type="tel"
            className={clsx(
              'w-full p-3  border-none rounded-md text-red sm:text-xl font-bold placeholder:opacity-40 placeholder:text-primary-600 focus:outline-none focus:ring-0',
              {
                'text-center': center,
                error: !!inputProps.error,
              },
            )}
            onKeyUp={(e) => {
              if (e.key === 'Backspace' && inputRef.current?.value === '') {
                searchInputRef.current?.focus();
              }
              if (
                e.key === 'ArrowLeft' &&
                inputRef.current?.selectionStart === 0
              ) {
                searchInputRef.current?.focus();
              }
            }}
            autoFocus
            onChange={onChange}
            ref={inputRef}
            {...inputProps}
          />
        </div>

        {inputProps.error && typeof inputProps.error !== 'boolean' && (
          <div className="flex justify-center">
            <span
              data-testid={`${inputProps.name}-error-message`}
              className={clsx(
                'error-message mt-6 py-1 px-4 text-sm font-bold text-white block rounded-full bg-danger text-center',
                { 'text-center': center },
              )}
            >
              {inputProps.error}
            </span>
          </div>
        )}
        {inputProps.message !== null && (
          <span className={'message'}>{inputProps.message}</span>
        )}
      </div>
    </>
  );
};

interface MaskedInputProps {
  error?: string | boolean | null;
  message?: string | number | null;
  icon?: ReactElement;
  center?: boolean;
  size?: 'big' | 'normal' | 'small';
}

export const MaskedInput: React.FC<
  React.HTMLProps<HTMLInputElement> & MaskedInputProps & ReactMaskedInputProps
> = ({ className, icon, center, ref, ...inputProps }) => {
  return (
    <>
      <div className={clsx('input-wrapper mx-auto max-w-sm', className)}>
        <label
          className={clsx(
            'input-label inline-block mb-2 font-bold text-gray-900',
            {
              error: !!inputProps.error,
            },
          )}
        >
          {inputProps.label}
        </label>
        <ReactMaskedInput
          type="text"
          className={clsx(
            'w-full rounded-md p-3 shadow-lg text-red sm:text-xl font-bold placeholder:opacity-40 placeholder:text-primary-600  focus:outline-none',
            {
              'text-center': center,
              error: !!inputProps.error,
            },
          )}
          {...inputProps}
        />
        {inputProps.error && typeof inputProps.error !== 'boolean' && (
          <div className="flex justify-center">
            <span
              data-testid={`${inputProps.name}-error-message`}
              className={clsx(
                'error-message mt-6 py-1 px-4 text-sm font-bold text-white block rounded-full bg-danger text-center',
                { 'text-center': center },
              )}
            >
              {inputProps.error}
            </span>
          </div>
        )}
        {inputProps.message !== null && (
          <span className={'message'}>{inputProps.message}</span>
        )}
      </div>
    </>
  );
};

interface InputProps {
  error?: string | boolean | null;
  message?: string | number | null;
  icon?: ReactElement;
  center?: boolean;
  size?: 'big' | 'normal' | 'small';
}

export const Input = React.forwardRef<
  HTMLInputElement,
  React.HTMLProps<HTMLInputElement> & InputProps
>(({ className, icon, center, required, ...inputProps }, ref) => {
  return (
    <>
      <div className={clsx('input-wrapper mx-auto max-w-sm', className)}>
        <div className="bg-white rounded-md py-2 px-3 shadow-lg relative">
          <label
            className={clsx(
              'input-label mb-2 text-red flex justify-between w-full',
              {
                error: !!inputProps.error,
              },
            )}
          >
            <span>{inputProps.label}</span>
            {required && <span className="text-red">*</span>}
          </label>
          <input
            type="text"
            className={clsx(
              'w-full pt-0 rounded-md border-none text-red sm:text-xl font-bold placeholder:opacity-40 placeholder:text-primary-600 focus:outline-none focus:ring-0 px-0',
              {
                'text-center': center,
                error: !!inputProps.error,
              },
            )}
            ref={ref}
            {...inputProps}
          />
        </div>
        {inputProps.error && typeof inputProps.error !== 'boolean' && (
          <div className="flex justify-center">
            <span
              data-testid={`${inputProps.name}-error-message`}
              className={clsx(
                'error-message mt-6 py-1 px-4 text-sm font-bold text-white block rounded-full bg-danger text-center',
                { 'text-center': center },
              )}
            >
              {inputProps.error}
            </span>
          </div>
        )}
        {inputProps.message !== null && (
          <span className={'message'}>{inputProps.message}</span>
        )}
      </div>
    </>
  );
});

Input.displayName = 'Input';
