//@ts-nocheck
/**
 * AUTO_GENERATED Do not change this file directly, use config.ts file instead
 *
 * @version 6
 */

import type { AxiosRequestConfig } from "axios";
import type { SwaggerResponse } from "./config";
import { Http } from "./httpRequest";
//@ts-ignore
import qs from "qs";
import type {
  GetProductFiltersAlcoholQueryParams,
  GetTransactionHistoryQueryParams,
  GetTransactionHistoryV2QueryParams,
  GetTransactionHistoryV2CardQueryParams,
  GetTransactionHistoryDetailsV2QueryParams,
  GetTransactionHistoryDetailsQueryParams,
  GetTransactionHistoryDetailsCardQueryParams,
  GetTransactionHistoryDetailsV2CardQueryParams,
  GetTransactionHistoryCardQueryParams,
  CheckVersionBodyDto,
  OnboardAuthDto,
  MessageDto,
  ConfirmSignupDto,
  SignupConfirmResponseDto,
  AuthCodeSendResponseDto,
  EmailAuthCodeConfirmBodyDto,
  UserDto,
  LoginBodyDto,
  LoginResponseDto,
  LoginClientCredentialsBodyDto,
  KeycloakLoginResponseDto,
  RefreshTokenBodyDto,
  RefreshTokenResponseDto,
  MarketingCommunicationsBodyDto,
  PasswordRemindBodyDto,
  PasswordRemindResponseDto,
  PasswordChangeBodyDto,
  EmailChangeBodyDto,
  EmailChangeConfirmBodyDto,
  PhoneChangeBodyDto,
  PhoneChangeConfirmBodyDto,
  ApkBugDto,
  SubmitApkErrorDto,
  DeviceSetupBodyDto,
  VersionCheckDto,
  ArticleDto,
  ArticleCategoryDto,
  CardDto,
  ConsentDto,
  UpdateConsentsBodyDto,
  SaveConsentsBodyDto,
  RegulationDto,
  PrivacyPolicyDto,
  UseReferralCodeDto,
  ValidateReferralCodeDto,
  ReferralDto,
  NotificationDto,
  DeleteNotificationBodyDto,
  CreateNotificationBodyDto,
  PlaceDto,
  PromotionSummaryDto,
  TransactionDto,
  CommentDto,
  ProductWithTransactionDto,
  ProductWithTransactionV2Dto,
  UpdateUserBodyDto,
  UserDetailsDto,
  CommentWineBodyDto,
  RateWineBodyDto,
  UpdatePreferencesBodyDto,
  UploadImageBodyDto,
  GatherImageDataBodyDto,
  ProductDto,
  ProductV2Dto,
  ProductSearchBodyDto,
  ProductSearchResultDto,
  AlcoholSearchResultDto,
  WineSearchResultDto,
  ProductFiltersInfoDto,
  AlcoholFiltersInfoDto,
  UserTokenDto,
  ValidateTokenDto,
  ValidateTokenUserDto,
  OnboardingEmailAuthCodeSendBodyDto,
  OnboardingEmailValidateBodyDto,
  OnboardingDto,
  OnboardingEmailAuthCodeConfirmBodyDto,
  EmailConfirmResponseDto,
  SmsAuthCodeBodyDto,
  SmsRetriverAuthCodeBodyDto,
  SmsAuthCodeConfirmBodyDto,
  PhoneConfirmResponseDto,
  OnboardingSignupBodyDto,
  TransactionHistoryPageDto,
  TransactionHistoryItemDetailsV2Dto,
  TransactionHistoryItemDetailsDto,
} from "./types";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const __DEV__ = process.env.NODE_ENV !== "production";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function overrideConfig(
  config?: AxiosRequestConfig,
  configOverride?: AxiosRequestConfig
): AxiosRequestConfig {
  return {
    ...config,
    ...configOverride,
    headers: {
      ...config?.headers,
      ...configOverride?.headers,
    },
  };
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function template(path: string, obj: { [x: string]: any } = {}) {
  Object.keys(obj).forEach((key) => {
    const re = new RegExp(`{${key}}`, "i");
    path = path.replace(re, obj[key]);
  });

  return path;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function objToForm(requestBody: object) {
  const formData = new FormData();

  Object.entries(requestBody).forEach(([key, value]) => {
    value && formData.append(key, value);
  });

  return formData;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function objToUrlencoded(requestBody: object) {
  return qs.stringify(requestBody);
}

export const deleteAccount = (
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<UserDto>> => {
  return Http.deleteRequest(
    deleteAccount.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
deleteAccount.key = "/account";

export const deleteNotNotificationsId = (
  id: string,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<NotificationDto>> => {
  return Http.deleteRequest(
    template(deleteNotNotificationsId.key, { id }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
deleteNotNotificationsId.key = "/not/notifications/{id}";

export const getApkErrorlist = (
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<ApkBugDto[]>> => {
  return Http.getRequest(
    getApkErrorlist.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getApkErrorlist.key = "/apk/errorlist";

export const getArticles = (
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<ArticleDto[]>> => {
  return Http.getRequest(
    getArticles.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getArticles.key = "/articles";

export const getArticlesCategory = (
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<ArticleCategoryDto[]>> => {
  return Http.getRequest(
    getArticlesCategory.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getArticlesCategory.key = "/articles/category";

export const getArticlesCategoryId = (
  id: number,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<ArticleDto[]>> => {
  return Http.getRequest(
    template(getArticlesCategoryId.key, { id }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getArticlesCategoryId.key = "/articles/category/{id}";

export const getArticlesCathegory = (
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<ArticleCategoryDto[]>> => {
  return Http.getRequest(
    getArticlesCathegory.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getArticlesCathegory.key = "/articles/cathegory";

export const getArticlesCathegoryId = (
  id: number,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<ArticleDto[]>> => {
  return Http.getRequest(
    template(getArticlesCathegoryId.key, { id }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getArticlesCathegoryId.key = "/articles/cathegory/{id}";

export const getArticlesId = (
  id: number,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<ArticleDto>> => {
  return Http.getRequest(
    template(getArticlesId.key, { id }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getArticlesId.key = "/articles/{id}";

export const getArticlesIdId = (
  id: number,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<ArticleDto>> => {
  return Http.getRequest(
    template(getArticlesIdId.key, { id }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getArticlesIdId.key = "/articles/id/{id}";

export const getArticlesUser = (
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<ArticleDto>> => {
  return Http.getRequest(
    getArticlesUser.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getArticlesUser.key = "/articles/user";

export const getCards = (
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<CardDto[]>> => {
  return Http.getRequest(
    getCards.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getCards.key = "/cards";

export const getConsents = (
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<ConsentDto[]>> => {
  return Http.getRequest(
    getConsents.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getConsents.key = "/consents";

export const getNotNotifications = (
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<NotificationDto[]>> => {
  return Http.getRequest(
    getNotNotifications.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getNotNotifications.key = "/not/notifications";

export const getPlaces = (
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<PlaceDto[]>> => {
  return Http.getRequest(
    getPlaces.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getPlaces.key = "/places";

export const getPolicies = (
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<PrivacyPolicyDto[]>> => {
  return Http.getRequest(
    getPolicies.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getPolicies.key = "/policies";

export const getPoliciesLatest = (
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<PrivacyPolicyDto[]>> => {
  return Http.getRequest(
    getPoliciesLatest.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getPoliciesLatest.key = "/policies/latest";

export const getProduct = (
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<ProductDto[]>> => {
  return Http.getRequest(
    getProduct.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getProduct.key = "/product";

export const getProductCommentsId = (
  id: string,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<CommentDto[]>> => {
  return Http.getRequest(
    template(getProductCommentsId.key, { id }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getProductCommentsId.key = "/product/comments/{id}";

/**
 * @deprecated This endpoint deprecated and will be remove. Please use an alternative
 */
export const getProductFilters = (
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<ProductFiltersInfoDto>> => {
  if (__DEV__) {
    console.warn(
      "getProductFilters",
      "This endpoint deprecated and will be remove. Please use an alternative"
    );
  }
  return Http.getRequest(
    getProductFilters.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getProductFilters.key = "/product/filters";

export const getProductFiltersAlcohol = (
  queryParams: GetProductFiltersAlcoholQueryParams,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<AlcoholFiltersInfoDto>> => {
  return Http.getRequest(
    getProductFiltersAlcohol.key,
    queryParams,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getProductFiltersAlcohol.key = "/product/filters/alcohol";

export const getProductFiltersWine = (
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<ProductFiltersInfoDto>> => {
  return Http.getRequest(
    getProductFiltersWine.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getProductFiltersWine.key = "/product/filters/wine";

export const getProductId = (
  id: string,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<ProductDto>> => {
  return Http.getRequest(
    template(getProductId.key, { id }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getProductId.key = "/product/{id}";

export const getProductV2 = (
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<ProductV2Dto[]>> => {
  return Http.getRequest(
    getProductV2.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getProductV2.key = "/product/v2";

export const getProductV2Id = (
  id: string,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<ProductV2Dto>> => {
  return Http.getRequest(
    template(getProductV2Id.key, { id }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getProductV2Id.key = "/product/v2/{id}";

export const getPromosUser = (
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<PromotionSummaryDto>> => {
  return Http.getRequest(
    getPromosUser.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getPromosUser.key = "/promos/user";

export const getReferalGenerate = (
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
  return Http.getRequest(
    getReferalGenerate.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getReferalGenerate.key = "/referal/generate";

export const getReferralGenerate = (
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
  return Http.getRequest(
    getReferralGenerate.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getReferralGenerate.key = "/referral/generate";

export const getRegulations = (
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<RegulationDto[]>> => {
  return Http.getRequest(
    getRegulations.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getRegulations.key = "/regulations";

export const getRegulationsTitles = (
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<RegulationDto[]>> => {
  return Http.getRequest(
    getRegulationsTitles.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getRegulationsTitles.key = "/regulations/titles";

export const getSyneriseToken = (
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
  return Http.getRequest(
    getSyneriseToken.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getSyneriseToken.key = "/synerise/token";

export const getSyneriseVerify_token = (
  headerParams?: { authorization: string },
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
  return Http.getRequest(
    getSyneriseVerify_token.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT1,
          ...headerParams,
        },
      },
      configOverride
    )
  );
};

/** Key is end point string without base url */
getSyneriseVerify_token.key = "/synerise/verify_token";

export const getTransactionHistory = (
  queryParams?: GetTransactionHistoryQueryParams,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<TransactionHistoryPageDto>> => {
  return Http.getRequest(
    getTransactionHistory.key,
    queryParams,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getTransactionHistory.key = "/transaction-history";

export const getTransactionHistoryCard = (
  card: string,
  queryParams?: GetTransactionHistoryCardQueryParams,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<TransactionHistoryPageDto>> => {
  return Http.getRequest(
    template(getTransactionHistoryCard.key, { card }),
    queryParams,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getTransactionHistoryCard.key = "/transaction-history/{card}";

export const getTransactionHistoryDetails = (
  queryParams: GetTransactionHistoryDetailsQueryParams,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<TransactionHistoryItemDetailsDto[]>> => {
  return Http.getRequest(
    getTransactionHistoryDetails.key,
    queryParams,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getTransactionHistoryDetails.key = "/transaction-history/details";

export const getTransactionHistoryDetailsCard = (
  card: string,
  queryParams: GetTransactionHistoryDetailsCardQueryParams,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<TransactionHistoryItemDetailsDto[]>> => {
  return Http.getRequest(
    template(getTransactionHistoryDetailsCard.key, { card }),
    queryParams,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getTransactionHistoryDetailsCard.key = "/transaction-history/details/{card}";

export const getTransactionHistoryDetailsV2 = (
  queryParams: GetTransactionHistoryDetailsV2QueryParams,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<TransactionHistoryItemDetailsV2Dto[]>> => {
  return Http.getRequest(
    getTransactionHistoryDetailsV2.key,
    queryParams,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getTransactionHistoryDetailsV2.key = "/transaction-history/details/v2";

export const getTransactionHistoryDetailsV2Card = (
  card: string,
  queryParams: GetTransactionHistoryDetailsV2CardQueryParams,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<TransactionHistoryItemDetailsV2Dto[]>> => {
  return Http.getRequest(
    template(getTransactionHistoryDetailsV2Card.key, { card }),
    queryParams,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getTransactionHistoryDetailsV2Card.key =
  "/transaction-history/details/v2/{card}";

export const getTransactionHistoryV2 = (
  queryParams?: GetTransactionHistoryV2QueryParams,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<TransactionHistoryPageDto>> => {
  return Http.getRequest(
    getTransactionHistoryV2.key,
    queryParams,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getTransactionHistoryV2.key = "/transaction-history/v2";

export const getTransactionHistoryV2Card = (
  card: string,
  queryParams?: GetTransactionHistoryV2CardQueryParams,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<TransactionHistoryPageDto>> => {
  return Http.getRequest(
    template(getTransactionHistoryV2Card.key, { card }),
    queryParams,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getTransactionHistoryV2Card.key = "/transaction-history/v2/{card}";

export const getTransactions = (
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<TransactionDto[]>> => {
  return Http.getRequest(
    getTransactions.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getTransactions.key = "/transactions";

export const getTransactionsCommented = (
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<ProductWithTransactionDto[]>> => {
  return Http.getRequest(
    getTransactionsCommented.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getTransactionsCommented.key = "/transactions/commented";

export const getTransactionsLiked = (
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<ProductWithTransactionDto[]>> => {
  return Http.getRequest(
    getTransactionsLiked.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getTransactionsLiked.key = "/transactions/liked";

export const getTransactionsPurchased = (
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<ProductWithTransactionDto[]>> => {
  return Http.getRequest(
    getTransactionsPurchased.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getTransactionsPurchased.key = "/transactions/purchased";

export const getTransactionsRated = (
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<ProductWithTransactionDto[]>> => {
  return Http.getRequest(
    getTransactionsRated.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getTransactionsRated.key = "/transactions/rated";

export const getTransactionsRecent = (
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<ProductWithTransactionDto[]>> => {
  return Http.getRequest(
    getTransactionsRecent.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getTransactionsRecent.key = "/transactions/recent";

export const getTransactionsRecommended = (
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<ProductWithTransactionDto[]>> => {
  return Http.getRequest(
    getTransactionsRecommended.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getTransactionsRecommended.key = "/transactions/recommended";

export const getTransactionsV2 = (
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<ProductWithTransactionV2Dto[]>> => {
  return Http.getRequest(
    getTransactionsV2.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getTransactionsV2.key = "/transactions/v2";

export const getTransactionsV2Commented = (
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<ProductWithTransactionV2Dto[]>> => {
  return Http.getRequest(
    getTransactionsV2Commented.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getTransactionsV2Commented.key = "/transactions/v2/commented";

export const getTransactionsV2Liked = (
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<ProductWithTransactionV2Dto[]>> => {
  return Http.getRequest(
    getTransactionsV2Liked.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getTransactionsV2Liked.key = "/transactions/v2/liked";

export const getTransactionsV2Purchased = (
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<ProductWithTransactionV2Dto[]>> => {
  return Http.getRequest(
    getTransactionsV2Purchased.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getTransactionsV2Purchased.key = "/transactions/v2/purchased";

export const getTransactionsV2Rated = (
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<ProductWithTransactionV2Dto[]>> => {
  return Http.getRequest(
    getTransactionsV2Rated.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getTransactionsV2Rated.key = "/transactions/v2/rated";

export const getTransactionsV2Recent = (
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<ProductWithTransactionV2Dto[]>> => {
  return Http.getRequest(
    getTransactionsV2Recent.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getTransactionsV2Recent.key = "/transactions/v2/recent";

export const getTransactionsV2Recommended = (
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<ProductWithTransactionV2Dto[]>> => {
  return Http.getRequest(
    getTransactionsV2Recommended.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getTransactionsV2Recommended.key = "/transactions/v2/recommended";

export const getUserDetails = (
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<UserDetailsDto>> => {
  return Http.getRequest(
    getUserDetails.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getUserDetails.key = "/user/details";

export const getUserLikeId = (
  id: string,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<MessageDto>> => {
  return Http.getRequest(
    template(getUserLikeId.key, { id }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getUserLikeId.key = "/user/like/{id}";

export const getUserProducts = (
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<ProductWithTransactionDto[]>> => {
  return Http.getRequest(
    getUserProducts.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getUserProducts.key = "/user/products";

export const getUserProductsId = (
  /**
   *
   * Pim ID
   */
  id: string,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<ProductWithTransactionDto>> => {
  return Http.getRequest(
    template(getUserProductsId.key, { id }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getUserProductsId.key = "/user/products/{id}";

export const getUserUnlikeId = (
  id: string,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<MessageDto>> => {
  return Http.getRequest(
    template(getUserUnlikeId.key, { id }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getUserUnlikeId.key = "/user/unlike/{id}";

export const getUserV2Products = (
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<ProductWithTransactionV2Dto[]>> => {
  return Http.getRequest(
    getUserV2Products.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getUserV2Products.key = "/user/v2/products";

export const postAccountAppOpened = (
  headerParams?: { platform: string; version: string },
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<LoginResponseDto>> => {
  return Http.postRequest(
    postAccountAppOpened.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT1,
          ...headerParams,
        },
      },
      configOverride
    )
  );
};

/** Key is end point string without base url */
postAccountAppOpened.key = "/account/app-opened";

export const postAccountAuthcodeConfirmEmail = (
  requestBody: EmailAuthCodeConfirmBodyDto,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<UserDto>> => {
  return Http.postRequest(
    postAccountAuthcodeConfirmEmail.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
postAccountAuthcodeConfirmEmail.key = "/account/authcode/confirm/email";

export const postAccountAuthcodeEmail = (
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<AuthCodeSendResponseDto>> => {
  return Http.postRequest(
    postAccountAuthcodeEmail.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
postAccountAuthcodeEmail.key = "/account/authcode/email";

export const postAccountConfirm = (
  requestBody: ConfirmSignupDto,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<SignupConfirmResponseDto>> => {
  return Http.postRequest(
    postAccountConfirm.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
postAccountConfirm.key = "/account/confirm";

export const postAccountConfirmonboard = (
  requestBody: ConfirmSignupDto,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<SignupConfirmResponseDto>> => {
  return Http.postRequest(
    postAccountConfirmonboard.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
postAccountConfirmonboard.key = "/account/confirmonboard";

export const postAccountEmailConfirm = (
  requestBody: EmailChangeConfirmBodyDto,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<UserDto>> => {
  return Http.postRequest(
    postAccountEmailConfirm.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
postAccountEmailConfirm.key = "/account/email/confirm";

export const postAccountLogin = (
  requestBody: LoginBodyDto,
  headerParams?: { version: string },
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<LoginResponseDto>> => {
  return Http.postRequest(
    postAccountLogin.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT1,
          ...headerParams,
        },
      },
      configOverride
    )
  );
};

/** Key is end point string without base url */
postAccountLogin.key = "/account/login";

export const postAccountLoginService = (
  requestBody: LoginClientCredentialsBodyDto,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<KeycloakLoginResponseDto>> => {
  return Http.postRequest(
    postAccountLoginService.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
postAccountLoginService.key = "/account/login-service";

export const postAccountMktgcommunitation = (
  requestBody: MarketingCommunicationsBodyDto,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<MessageDto>> => {
  return Http.postRequest(
    postAccountMktgcommunitation.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
postAccountMktgcommunitation.key = "/account/mktgcommunitation";

export const postAccountOnboardauth = (
  requestBody: OnboardAuthDto,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<MessageDto>> => {
  return Http.postRequest(
    postAccountOnboardauth.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
postAccountOnboardauth.key = "/account/onboardauth";

export const postAccountPasswordchange = (
  requestBody: PasswordChangeBodyDto,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<MessageDto>> => {
  return Http.postRequest(
    postAccountPasswordchange.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
postAccountPasswordchange.key = "/account/passwordchange";

export const postAccountPasswordremind = (
  requestBody: PasswordRemindBodyDto,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<PasswordRemindResponseDto>> => {
  return Http.postRequest(
    postAccountPasswordremind.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
postAccountPasswordremind.key = "/account/passwordremind";

export const postAccountPhoneConfirm = (
  requestBody: PhoneChangeConfirmBodyDto,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<UserDto>> => {
  return Http.postRequest(
    postAccountPhoneConfirm.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
postAccountPhoneConfirm.key = "/account/phone/confirm";

export const postAccountRefresh_token = (
  requestBody: RefreshTokenBodyDto,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<RefreshTokenResponseDto>> => {
  return Http.postRequest(
    postAccountRefresh_token.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
postAccountRefresh_token.key = "/account/refresh_token";

export const postAccountVersioncheck = (
  requestBody: CheckVersionBodyDto,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    postAccountVersioncheck.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
postAccountVersioncheck.key = "/account/versioncheck";

export const postApkDevice = (
  requestBody: DeviceSetupBodyDto,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<MessageDto>> => {
  return Http.postRequest(
    postApkDevice.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
postApkDevice.key = "/apk/device";

export const postApkSubmiterror = (
  requestBody: SubmitApkErrorDto,
  headerParams?: { platform: string; version: string },
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<ApkBugDto>> => {
  return Http.postRequest(
    postApkSubmiterror.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT1,
          ...headerParams,
        },
      },
      configOverride
    )
  );
};

/** Key is end point string without base url */
postApkSubmiterror.key = "/apk/submiterror";

export const postApkVersioncheck = (
  requestBody: CheckVersionBodyDto,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<VersionCheckDto>> => {
  return Http.postRequest(
    postApkVersioncheck.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
postApkVersioncheck.key = "/apk/versioncheck";

export const postConsents = (
  requestBody: SaveConsentsBodyDto,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<MessageDto>> => {
  return Http.postRequest(
    postConsents.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
postConsents.key = "/consents";

export const postExternalLink = (
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<UserTokenDto>> => {
  return Http.postRequest(
    postExternalLink.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
postExternalLink.key = "/external-link";

export const postExternalLinkValidate = (
  requestBody: ValidateTokenDto,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<ValidateTokenUserDto>> => {
  return Http.postRequest(
    postExternalLinkValidate.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
postExternalLinkValidate.key = "/external-link/validate";

export const postNotDelnotification = (
  requestBody: DeleteNotificationBodyDto,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<NotificationDto>> => {
  return Http.postRequest(
    postNotDelnotification.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
postNotDelnotification.key = "/not/delnotification";

export const postNotNotifications = (
  requestBody: CreateNotificationBodyDto,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<NotificationDto>> => {
  return Http.postRequest(
    postNotNotifications.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
postNotNotifications.key = "/not/notifications";

export const postNotNotificationsAddnotification = (
  requestBody: CreateNotificationBodyDto,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<NotificationDto>> => {
  return Http.postRequest(
    postNotNotificationsAddnotification.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
postNotNotificationsAddnotification.key = "/not/notifications/addnotification";

export const postOnboardingAuthcodeConfirmEmail = (
  requestBody: OnboardingEmailAuthCodeConfirmBodyDto,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<EmailConfirmResponseDto>> => {
  return Http.postRequest(
    postOnboardingAuthcodeConfirmEmail.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
postOnboardingAuthcodeConfirmEmail.key = "/onboarding/authcode/confirm/email";

export const postOnboardingAuthcodeConfirmSms = (
  requestBody: SmsAuthCodeConfirmBodyDto,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<PhoneConfirmResponseDto>> => {
  return Http.postRequest(
    postOnboardingAuthcodeConfirmSms.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
postOnboardingAuthcodeConfirmSms.key = "/onboarding/authcode/confirm/sms";

export const postOnboardingAuthcodeEmail = (
  requestBody: OnboardingEmailAuthCodeSendBodyDto,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<AuthCodeSendResponseDto>> => {
  return Http.postRequest(
    postOnboardingAuthcodeEmail.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
postOnboardingAuthcodeEmail.key = "/onboarding/authcode/email";

export const postOnboardingAuthcodeRetriverSms = (
  requestBody: SmsRetriverAuthCodeBodyDto,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<AuthCodeSendResponseDto>> => {
  return Http.postRequest(
    postOnboardingAuthcodeRetriverSms.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
postOnboardingAuthcodeRetriverSms.key = "/onboarding/authcode/retriver-sms";

export const postOnboardingAuthcodeSms = (
  requestBody: SmsAuthCodeBodyDto,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<AuthCodeSendResponseDto>> => {
  return Http.postRequest(
    postOnboardingAuthcodeSms.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
postOnboardingAuthcodeSms.key = "/onboarding/authcode/sms";

export const postOnboardingSignup = (
  requestBody: OnboardingSignupBodyDto,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<LoginResponseDto>> => {
  return Http.postRequest(
    postOnboardingSignup.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
postOnboardingSignup.key = "/onboarding/signup";

export const postOnboardingValidateEmail = (
  requestBody: OnboardingEmailValidateBodyDto,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<OnboardingDto>> => {
  return Http.postRequest(
    postOnboardingValidateEmail.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
postOnboardingValidateEmail.key = "/onboarding/validate/email";

export const postPoliciesUpdate = (
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<MessageDto>> => {
  return Http.postRequest(
    postPoliciesUpdate.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
postPoliciesUpdate.key = "/policies/update";

/**
 * @deprecated This endpoint deprecated and will be remove. Please use an alternative
 */
export const postProductSearch = (
  requestBody: ProductSearchBodyDto,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<ProductSearchResultDto>> => {
  if (__DEV__) {
    console.warn(
      "postProductSearch",
      "This endpoint deprecated and will be remove. Please use an alternative"
    );
  }
  return Http.postRequest(
    postProductSearch.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
postProductSearch.key = "/product/search";

export const postProductSearchAlcohol = (
  requestBody: ProductSearchBodyDto,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<ProductSearchResultDto>> => {
  return Http.postRequest(
    postProductSearchAlcohol.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
postProductSearchAlcohol.key = "/product/search/alcohol";

export const postProductSearchAlcoholCard = (
  card: string,
  requestBody: ProductSearchBodyDto,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<AlcoholSearchResultDto>> => {
  return Http.postRequest(
    template(postProductSearchAlcoholCard.key, { card }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
postProductSearchAlcoholCard.key = "/product/search/alcohol/{card}";

export const postProductSearchCard = (
  card: string,
  requestBody: ProductSearchBodyDto,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<ProductSearchResultDto>> => {
  return Http.postRequest(
    template(postProductSearchCard.key, { card }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
postProductSearchCard.key = "/product/search/{card}";

export const postProductSearchWine = (
  requestBody: ProductSearchBodyDto,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<ProductSearchResultDto>> => {
  return Http.postRequest(
    postProductSearchWine.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
postProductSearchWine.key = "/product/search/wine";

export const postProductSearchWineCard = (
  card: string,
  requestBody: ProductSearchBodyDto,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<WineSearchResultDto>> => {
  return Http.postRequest(
    template(postProductSearchWineCard.key, { card }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
postProductSearchWineCard.key = "/product/search/wine/{card}";

export const postReferalUse = (
  requestBody: UseReferralCodeDto,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    postReferalUse.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
postReferalUse.key = "/referal/use";

export const postReferalValidate = (
  requestBody: ValidateReferralCodeDto,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<ReferralDto>> => {
  return Http.postRequest(
    postReferalValidate.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
postReferalValidate.key = "/referal/validate";

export const postReferralUse = (
  requestBody: UseReferralCodeDto,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    postReferralUse.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
postReferralUse.key = "/referral/use";

export const postReferralValidate = (
  requestBody: ValidateReferralCodeDto,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<ReferralDto>> => {
  return Http.postRequest(
    postReferralValidate.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
postReferralValidate.key = "/referral/validate";

export const postSearchGatherimagedata = (
  requestBody: GatherImageDataBodyDto,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    postSearchGatherimagedata.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
postSearchGatherimagedata.key = "/search/gatherimagedata";

export const postSearchImage = (
  requestBody: UploadImageBodyDto,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    postSearchImage.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
postSearchImage.key = "/search/image";

export const postUserApprated = (
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    postUserApprated.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
postUserApprated.key = "/user/apprated";

export const postUserComment = (
  requestBody: CommentWineBodyDto,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<MessageDto>> => {
  return Http.postRequest(
    postUserComment.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
postUserComment.key = "/user/comment";

export const postUserPreferences = (
  requestBody: UpdatePreferencesBodyDto,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<MessageDto>> => {
  return Http.postRequest(
    postUserPreferences.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
postUserPreferences.key = "/user/preferences";

export const postUserRate = (
  requestBody: RateWineBodyDto,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<MessageDto>> => {
  return Http.postRequest(
    postUserRate.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
postUserRate.key = "/user/rate";

export const putAccountEmail = (
  requestBody: EmailChangeBodyDto,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<AuthCodeSendResponseDto>> => {
  return Http.putRequest(
    putAccountEmail.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
putAccountEmail.key = "/account/email";

export const putAccountPhone = (
  requestBody: PhoneChangeBodyDto,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<AuthCodeSendResponseDto>> => {
  return Http.putRequest(
    putAccountPhone.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
putAccountPhone.key = "/account/phone";

export const putConsents = (
  requestBody: UpdateConsentsBodyDto,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<MessageDto>> => {
  return Http.putRequest(
    putConsents.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
putConsents.key = "/consents";

export const putUserProfile = (
  requestBody: UpdateUserBodyDto,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<UserDetailsDto>> => {
  return Http.putRequest(
    putUserProfile.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
putUserProfile.key = "/user/profile";
export const _CONSTANT0 = {
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
};
export const _CONSTANT1 = {
  "Content-Type": "application/json",
  Accept: "application/json",
};
