import { useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  usePostOnboardingAuthcodeConfirmSms,
  usePostOnboardingAuthcodeSms,
} from '../services-hooks/hooks';
import {
  CodeConfirmResult,
  LoginResponseDto,
  OnboardingDto,
} from '../services-hooks/types';
import { Header } from './header.component';
import { Button } from './ui/button.component';
import { Input, PhoneInput } from './ui/input.component';
import { Link, useLocation } from 'react-router-dom';

interface AuthSmsFormProps {
  mode: 'login' | 'signup';
  onOnboardingStart: (onboarding: OnboardingDto) => void;
  onSessionStart: (loginResponse: LoginResponseDto) => void;
  onGoBack: VoidFunction;
}

export const AuthSmsForm: React.FC<AuthSmsFormProps> = ({
  onOnboardingStart,
  onSessionStart,
  onGoBack,
  mode,
}) => {
  const [phoneToValidate, setPhoneToValidate] = useState<string | null>(null);
  return (
    <>
      <Header
        onGoBack={
          phoneToValidate !== null
            ? () => {
                setPhoneToValidate(null);
              }
            : undefined
        }
        onExit={phoneToValidate === null ? onGoBack : undefined}
      />
      <div className="p-5">
        {phoneToValidate === null ? (
          <AuthSmsSendForm
            mode={mode}
            onSuccess={(phone) => setPhoneToValidate(phone)}
          />
        ) : (
          <AuthSmsConfirmForm
            phone={phoneToValidate}
            onOnboardingStart={onOnboardingStart}
            onSessionStart={onSessionStart}
          />
        )}
      </div>
    </>
  );
};

interface AuthSmsSendFormValue {
  phone: string;
}
interface AuthSmsSendFormProps {
  onSuccess: (phone: string) => void;
  mode: 'login' | 'signup';
}
const AuthSmsSendForm: React.FC<AuthSmsSendFormProps> = ({
  onSuccess,
  mode,
}) => {
  const location = useLocation();
  const redirectUrl = useMemo(
    () => new URLSearchParams(location.search).get('redirectUrl'),
    [location],
  );
  const { control, formState, watch, handleSubmit, setError } =
    useForm<AuthSmsSendFormValue>();
  const { mutateAsync, isLoading } = usePostOnboardingAuthcodeSms();
  const phoneValue = watch('phone');
  const siteKey = '6LcLbGwmAAAAAEC0AthraluDkoT9YwLDiw3bywTt';
  const onSubmit = async (value: AuthSmsSendFormValue) => {
    try {
      const token: string = await (window as any).grecaptcha.enterprise.execute(
        siteKey,
        {
          action: 'sms_send',
        },
      );
      const response = await mutateAsync({
        requestBody: {
          phone: value.phone,
          siteKey: siteKey,
          recaptcha: token,
        },
      });
      if (response.success) {
        onSuccess(value.phone);
      } else {
        setError('phone', {
          message: "Wystąpił problem. " + response.message,
        });
      }
    } catch (e: (Error | any)) {
      setError('phone', {
        message: "Wystąpił problem. " + e.message.message,
      });
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        {mode === 'login' ? (
          <>
            <h1 className="text-center text-2xl text-white font-bold mb-2">
              Zaloguj się używając Kocham Wino
            </h1>
            <p className="text-white text-lg text-center">
              Podaj swój numer telefonu aby zalogować się na Winezja.pl
            </p>
          </>
        ) : (
          <>
            <h1 className="text-center text-2xl text-white font-bold mb-2">
              Dołącz do Klubu Kocham Wino
            </h1>
            <p className="text-white text-lg text-center">
              Podaj swój numer telefonu aby utworzyć konto w Klubie Kocham Wino
            </p>
          </>
        )}
        <Controller
          name="phone"
          control={control}
          rules={{
            required: true,
            validate: (phone) => {
              if (phone.match(/^[\+]?\d+$/g)) {
                return true;
              }
              return 'Wprowadź poprawny numer telefonu';
            },
          }}
          render={({ field }) => (
            <PhoneInput
              autoFocus
              placeholder="000000000"
              className="mt-5"
              error={formState.errors.phone?.message}
              onValueChange={(dir, phone) => {
                field.onChange(`${dir}${phone}`);
              }}
            />
          )}
        />
        <Button
          loading={isLoading}
          disabled={!phoneValue || phoneValue?.length === 0 || isLoading}
          className="mt-5"
        >
          Dalej
        </Button>
      </form>
      {mode === 'login' ? (
        <div className="text-center mt-5">
          <span className="text-white font-bold">Nie masz konta?</span>{' '}
          <Link
            to={redirectUrl ? `/signup?redirectUrl=${redirectUrl}` : `/signup`}
            className="text-white underline"
          >
            Zarejestruj się
          </Link>
        </div>
      ) : (
        <div className="text-center mt-5">
          <span className="text-white font-bold">Masz konto?</span>{' '}
          <Link
            to={redirectUrl ? `/login?redirectUrl=${redirectUrl}` : `/login`}
            className="text-white underline"
          >
            Zaloguj się
          </Link>
        </div>
      )}
      <div className="px-2 sm:px-10 lg:px-0">
        <div className="text-center text-white mt-20">
          <p className="font-bold mb-1">Dlaczego prosimy o te dane?</p>
          <p className="text-sm">
            Twój numer telefonu jest i będzie Twoim loginem. Potrzebujemy go,
            aby móc Cię identyfikować jako Klubowicza Kocham Wino.
          </p>
        </div>
        <div className="text-center text-white mt-6">
          <p className="font-bold mb-1">
            Potrzebujesz pomocy lub masz pytania?
          </p>
          <p className="text-sm">
            Napisz do nas:{' '}
            <span className="underline">kochamwino@kochamwino.com.pl</span>
          </p>
        </div>
        <div className="text-center text-white mt-6">
          <p className="text-sm">
            Aby przejść dalej, Kocham Wino udostępni Winezji Twój numer telefonu
            oraz adres e-mail. Możesz zapoznać się z{' '}
            <a
              className="underline"
              target="_blank"
              rel="noopener noreferrer"
              href="https://winezja.pl/Uploads/Polityka-prywatnosci.pdf"
            >
              polityką prywatności
            </a>{' '}
            i{' '}
            <a
              className="underline"
              target="_blank"
              rel="noopener noreferrer"
              href="https://winezja.pl/Uploads/Regulamin-Witryny-Winezja.pdf"
            >
              warunkami korzystania
            </a>{' '}
            z serwisu Winezja.pl.
          </p>
        </div>
      </div>
    </>
  );
};

interface AuthSmsConfirmFormValue {
  code: string;
}
interface AuthSmsConfirmProps {
  phone: string;
  onOnboardingStart: (onboarding: OnboardingDto) => void;
  onSessionStart: (loginResponse: LoginResponseDto) => void;
}

const AuthSmsConfirmForm: React.FC<AuthSmsConfirmProps> = ({
  phone,
  onOnboardingStart,
  onSessionStart,
}) => {
  const { register, formState, watch, handleSubmit, setError } =
    useForm<AuthSmsConfirmFormValue>();
  const { mutateAsync, isLoading } = usePostOnboardingAuthcodeConfirmSms();
  const {
    mutateAsync: resendAsync,
    isLoading: resendLoading,
    data: resendData,
  } = usePostOnboardingAuthcodeSms();
  const codeValue = watch('code');

  const onSubmit = async (value: AuthSmsConfirmFormValue) => {
    try {
      const response = await mutateAsync({
        requestBody: {
          phone: phone,
          code: value.code,
        },
      });
      if (response.type === CodeConfirmResult.Onboarding) {
        onOnboardingStart(response.onboarding!);
      } else {
        onSessionStart(response.session!);
      }
    } catch (e) {
      setError('code', {
        message: 'Wprowadzony kod jest niepoprawny, spróbuj ponownie.',
      });
    }
  };

  const onResend = async () => {
    try {
      const siteKey = '6LcLbGwmAAAAAEC0AthraluDkoT9YwLDiw3bywTt';
      const token: string = await (window as any).grecaptcha.enterprise.execute(
        siteKey,
        {
          action: 'sms_send',
        },
      );
      const response = await resendAsync({
        requestBody: {
          phone: phone,
          siteKey: siteKey,
          recaptcha: token,
        },
      });
    } catch (e) {
      console.error(e);
    }
  };
  // const formattedPhone = useMemo(() => {
  //   const countryCode = phone.slice(0, 3);
  //   const firstPart = phone.slice(3, 6);
  //   const secondPart = phone.slice(6, 9);
  //   const thirdPart = phone.slice(9);
  //
  //   return `${countryCode} ${firstPart} ${secondPart} ${thirdPart}`;
  // }, [phone]);
  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className="mx-auto max-w-sm">
        <h1 className="text-center text-2xl text-white font-bold mb-2">
          Wpisz 6-cyfrowy kod wysłany na {phone}
        </h1>
        <p className="text-white text-lg text-center">Wpisz kod SMS:</p>
        <Input
          autoFocus
          className="mt-5"
          placeholder="000000"
          {...register('code', { required: true })}
          error={formState.errors.code?.message}
        />
        <Button
          loading={isLoading}
          disabled={!codeValue || codeValue?.length === 0 || isLoading}
          className="mt-5"
        >
          Dalej
        </Button>
      </form>
      <div className="text-center text-white mt-5 px-5 lg:px-0 flex flex-col sm:flex-row items-center justify-center">
        <p className="font-bold mr-2">Nie otrzymałeś kodu SMS? </p>
        <button
          onClick={onResend}
          className="underline"
          disabled={resendLoading}
        >
          Wyślij ponownie!
        </button>
      </div>
      <div>
        <div className="flex justify-center">
          {resendLoading && (
            <svg
              aria-hidden="true"
              role="status"
              className="w-4 h-4 text-white animate-spin"
              viewBox="0 0 100 101"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="#E5E7EB"
              />
              <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="currentColor"
              />
            </svg>
          )}
        </div>
        {resendData && (
          <>
            {resendData.success ? (
              <div className="text-center text-white mt-2">
                <p className="text-sm">Wysłano ponownie kod sms</p>
              </div>
            ) : (
              <div className="text-center text-danger mt-2">
                <p className="text-sm">
                  Nie udało się wysłać kodu sms, spróbuj ponownie
                </p>
              </div>
            )}
          </>
        )}
        <div className="text-center text-white mt-8">
          <p className="font-bold mb-1">
            Potrzebujesz pomocy lub masz pytania?
          </p>
          <p className="text-sm">
            Napisz do nas:{' '}
            <span className="underline">kochamwino@kochamwino.com.pl</span>
          </p>
        </div>
      </div>
    </>
  );
};
